import { useEffect, useState } from 'react'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )
    const [isMobileLayout, setMobileLayout] = useState(true)

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (
            windowDimensions.width <= 500 ||
            (windowDimensions.width > windowDimensions.height &&
                windowDimensions.height <= 500)
        ) {
            setMobileLayout(true)
        } else {
            setMobileLayout(false)
        }
    }, [windowDimensions])

    return { ...windowDimensions, isMobileLayout }
}
