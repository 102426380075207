import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Col, Modal, Row, Space, Spin, Tabs, Typography } from 'antd'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import VisualizacaoGeralUsina from './VisualizacaoGeralUsina'
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons'
import Inspecoes from './Inspecoes'
import EquipeDrawer from './EquipeDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountContext } from '../../../utils/Auth'
import { regionaisList } from '../Usinas'
import usePWAInstall from '../../../utils/usePWAInstall'
import { useLocalDb } from '../../../utils/useLocalDb'

const UsinaEspecifica = () => {
    const { message } = App.useApp()
    const { isInstalled } = usePWAInstall()
    const { isMobileLayout } = useWindowDimensions()
    const [tab, setTab] = useState('visualizacaoGeral')
    const [data, setData] = useState<any>(undefined)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [isEquipeDrawerOpen, setEquipeDrawerOpen] = useState<boolean>(false)
    let { id } = useParams()
    const navigate = useNavigate()
    const { authenticatedFetch, getEmailUsuario, getTipoUsuario } =
        useContext(AccountContext)
    const { getUsinaFromLocalDb, isOnline } = useLocalDb()

    useEffect(() => {
        getUsina()
    }, [isInstalled])

    const getUsina = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
            'GET',
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setData(data)
                        })
                    } else if (res.status === 404) {
                        message.error({
                            key: 'usinaFetchError',
                            content: 'Usina não encontrada',
                            duration: 8,
                        })
                    }
                },
            )
            .catch(() => {
                if (isInstalled && typeof id === 'string' && !isOnline) {
                    getUsinaFromLocalDb(parseInt(id)).then((data) => {
                        setData(data)
                    })
                } else if (isOnline) {
                    message.error({
                        key: 'usinaFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                }
            })
    }

    const tabList = [
        {
            key: 'visualizacaoGeral',
            label: 'Visualização geral',
        },
        {
            key: 'inspecoes',
            label: 'Inspeções',
        },
    ]

    const getFormattedDate = (date: Date) => {
        return new Date(date).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            //hour: 'numeric',
            //minute: '2-digit',
        })
    }

    const getRegionalIdr = (regional: string) => {
        return regionaisList.filter((e) => e.value === regional)[0].label
    }

    const removerUsina = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
            'DELETE',
        )
            .then((res: any) => {
                if (res.ok) {
                    message.success({
                        key: 'usinaDeleteSuccess',
                        content: 'Usina removida com sucesso',
                        duration: 6,
                    })
                    navigate('/app/usinas')
                } else {
                    message.error({
                        key: 'usinaDeleteError',
                        content: 'Erro ao remover usina',
                        duration: 6,
                    })
                }
            })
            .catch(() => {
                message.error({
                    key: 'usinaDeleteError',
                    content: 'Erro ao tentar contactar o servidor',
                    duration: 6,
                })
            })
    }

    const removerUsinaModal = () => (
        <Modal
            title={
                <>
                    <WarningOutlined /> Remover usina
                </>
            }
            open={showDeleteModal}
            okText="Remover"
            cancelText="Cancelar"
            onCancel={(e) => {
                setShowDeleteModal(false)
            }}
            onOk={(e) => {
                removerUsina()
            }}
            okButtonProps={{ danger: true }}
            zIndex={1001}
        >
            A usina e todas inspeções nela realizadas serão permanentemente
            removidas. Essa ação não poderá ser desfeita. Deseja continuar?
        </Modal>
    )

    return (
        <>
            {data !== undefined ? (
                <>
                    {removerUsinaModal()}
                    <Card
                        title={data.nome}
                        extra={
                            data &&
                            data.controlador &&
                            (getEmailUsuario() === data.controlador.email ||
                                getTipoUsuario() === 'ADMIN') && (
                                <Space>
                                    <Button
                                        disabled={!isOnline}
                                        shape={'circle'}
                                        onClick={(e) => {
                                            setShowDeleteModal(true)
                                        }}
                                        icon={<DeleteOutlined />}
                                    />
                                    <Button
                                        disabled={!isOnline}
                                        shape={'circle'}
                                        onClick={(e) => {
                                            navigate(
                                                '/app/usinas/' +
                                                id +
                                                '/modificar',
                                            )
                                        }}
                                        icon={<EditOutlined />}
                                    />
                                </Space>
                            )
                        }
                    >
                        <Row>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 10 }}
                                lg={{ span: 12 }}
                                xl={{ span: 12 }}
                            >
                                {data.dataCriacao && (
                                    <div>
                                        <Typography.Text
                                            strong
                                            style={{ marginRight: '3px' }}
                                        >
                                            Adicionada no sistema:
                                        </Typography.Text>
                                        <Typography.Text>
                                            {getFormattedDate(data.dataCriacao)}
                                        </Typography.Text>
                                    </div>
                                )}

                                <div>
                                    <Typography.Text
                                        strong
                                        style={{ marginRight: '3px' }}
                                    >
                                        Regional do IDR:
                                    </Typography.Text>
                                    <Typography.Text>
                                        {getRegionalIdr(data.regionalIdr)}
                                    </Typography.Text>
                                </div>
                                {data.controlador && (
                                    <div>
                                        <Typography.Text
                                            strong
                                            style={{ marginRight: '3px' }}
                                        >
                                            Controlador:
                                        </Typography.Text>

                                        <Typography.Text>
                                            {data.controlador.nome}{' '}
                                            <Typography.Text type={'secondary'}>
                                                ({data.controlador.email})
                                            </Typography.Text>
                                        </Typography.Text>
                                    </div>
                                )}
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 6 }}
                                lg={{ span: 8 }}
                                xl={{ span: 9 }}
                            >
                                <div style={{ height: 16 }}></div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 4 }}
                                xl={{ span: 3 }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: '100%' }}
                                        onClick={(e) =>
                                            navigate(
                                                '/app/usinas/' +
                                                id +
                                                '/inspecao/nova/',
                                            )
                                        }
                                    >
                                        Nova inspeção
                                    </Button>
                                    <Button
                                        disabled={!isOnline}
                                        style={{ width: '100%' }}
                                        onClick={(e) => {
                                            setEquipeDrawerOpen(true)
                                            navigate(
                                                '/app/usinas/' + id + '/equipe',
                                            )
                                        }}
                                    >
                                        Ver equipe
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ marginTop: 8 }}>
                        <Tabs
                            activeKey={tab}
                            size={isMobileLayout ? 'small' : 'large'}
                            onChange={(key) => setTab(key)}
                            items={tabList}
                            tabBarStyle={{ marginTop: '-24px' }}
                        />

                        {tab === 'visualizacaoGeral' && (
                            <VisualizacaoGeralUsina data={data} />
                        )}
                        {tab === 'inspecoes' && (
                            <Inspecoes
                                controlador={
                                    data.controlador
                                        ? data.controlador
                                        : { id: '', nome: '', email: '' }
                                }
                                idUsina={id}
                                usinaData={data}
                            />
                        )}
                    </Card>
                    <EquipeDrawer
                        open={isEquipeDrawerOpen}
                        setOpen={setEquipeDrawerOpen}
                        idUsina={id}
                        controlador={
                            data.controlador
                                ? data.controlador
                                : { id: '', nome: '', email: '' }
                        }
                        refreshControlador={getUsina}
                    />
                </>
            ) : (
                <>
                    <Spin style={{ width: '100%' }} />
                </>
            )}
        </>
    )
}

export default UsinaEspecifica