import React from 'react'
import { Button, Col, Form, Row, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    fileList: any[]
    setFileList: any
    formType: string
}

const FormStep6 = (props: Props) => {
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }

    return (
        <>
            <Row>
                <Col md={25} lg={18} xl={14} xxl={10}>
                    <Form.Item
                        label={'Fotos da visita'}
                        name={'fotos'}
                        rules={[
                            {
                                required: false,
                                message: 'Campo obrigatório',
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload
                            accept="image/*"
                            beforeUpload={() => false}
                            listType="picture"
                            maxCount={15}
                        >
                            <Button icon={<UploadOutlined />}>
                                Selecionar as fotos
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    htmlType={'submit'}
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                >
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep6
