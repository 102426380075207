import React, { useContext, useEffect } from 'react'
import { Button, Card, Checkbox, Col, Form, Input, Row, Space } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import NapiLogo from '../images/napi_logo_horizontal.svg'
import '../css/auth.css'
import { AccountContext } from '../utils/Auth'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const { authenticate, hasRefreshToken } = useContext(AccountContext)
    const navigate = useNavigate()
    const [form] = Form.useForm()

    useEffect(() => {
        if (hasRefreshToken()) {
            navigate('/app/dashboard')
        }
    }, [])

    const onFinish = () => {
        const formFields = form.getFieldsValue()
        authenticate(formFields.email, formFields.senha, formFields.lembrar)
    }

    return (
        <div className={'auth-bg'}>
            <div className={'auth-container'}>
                <Space
                    direction="vertical"
                    size={'large'}
                    style={{ width: '100%' }}
                >
                    <Row align={'middle'} justify={'center'}>
                        <img
                            src={NapiLogo}
                            alt={'Logo do NAPI Solar'}
                            width={300}
                            style={{ display: 'block' }}
                        />
                    </Row>
                    <Row align={'middle'} justify={'center'}>
                        <Card className={'auth-card'}>
                            <Form
                                name="login"
                                initialValues={{ lembrar: true }}
                                onFinish={onFinish}
                                form={form}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message:
                                                'Por favor entre com seu e-mail',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={
                                            <MailOutlined
                                                style={{ color: '#d9d9d9' }}
                                            />
                                        }
                                        placeholder="Email"
                                        size={'large'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="senha"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor entre com sua senha',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined
                                                style={{ color: '#d9d9d9' }}
                                            />
                                        }
                                        placeholder="Senha"
                                        visibilityToggle={true}
                                        maxLength={16}
                                        size={'large'}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col>
                                            <Form.Item
                                                name="lembrar"
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                <Checkbox>
                                                    Manter logado
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col style={{ marginLeft: 'auto' }}>
                                            <a href="/recuperarsenha">
                                                Esqueci minha senha
                                            </a>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        size={'large'}
                                    >
                                        Entrar
                                    </Button>
                                </Form.Item>
                                Ainda não possui cadastro?{' '}
                                <a href="/cadastro">Criar nova conta</a>
                            </Form>
                        </Card>
                    </Row>
                </Space>
            </div>
        </div>
    )
}

Login.propTypes = {}

export default Login
