import React from 'react'
import { Button, Col, Form, Row } from 'antd'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    formType: string
}

const FormStep4 = (props: Props) => {
    const loadedQuestions = questions();

    return (
        <>
            <Row>
                <Col md={24} lg={18} xl={14} xxl={10}>
                    {(props.formType !== 'TECNICA') &&
                        'Não há itens nessa seção para esse nível de formulário'}
                    <QuestionWrapper
                        question={loadedQuestions.q35}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q36}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q37}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q38}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q39}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q40}
                        formType={props.formType}
                    />

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                    onClick={(e) =>
                                        props.handleStepChange(
                                            props.currentStep + 1,
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep4
