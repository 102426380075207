import React, { useEffect, useState } from 'react'
import { Alert, Button } from 'antd'
import usePWAInstall from '../utils/usePWAInstall'
import useWindowDimensions from '../utils/useWindowDimensions'

const PWAInstallPrompt = () => {
    const [isDismissed, setIsDismissed] = useState(true)
    const { isInstalled, install } = usePWAInstall()
    const { isMobileLayout } = useWindowDimensions()

    useEffect(() => {
        const value = `; ${document.cookie}`
        const parts: Array<any> = value.split(`; pwaInstallDismissed=`)
        if (parts.length === 2) {
            const cookie = parts.pop().split(';').shift()
            setIsDismissed(cookie)
        } else {
            setIsDismissed(false)
        }
    }, [])

    const registerPwaInstallDismissal = () => {
        //60*60*24*30 = 2592000 (30d)
        document.cookie =
            'pwaInstallDismissed=true; ' +
            'Path=/; ' +
            'SameSite=Strict; ' +
            'max-age=2592000;'
        setIsDismissed(true)
    }

    return (
        <>
            {!isInstalled && !isDismissed && (
                <Alert
                    banner
                    closable
                    message={
                        <>
                            Você sabia que é possível{' '}
                            {isMobileLayout ? <br /> : ''}
                            usar esse aplicativo offline?
                        </>
                    }
                    type="info"
                    onClose={registerPwaInstallDismissal}
                    action={
                        <Button size="small" type={'text'} onClick={install}>
                            Instalar
                        </Button>
                    }
                />
            )}
        </>
    )
}

export default PWAInstallPrompt
