import React, { useEffect, useState } from 'react'
import { Button, Drawer } from 'antd'

const PWAInstallPrompt = () => {
    const [cookiesAllowed, setCookiesAllowed] = useState(false)
    const [open, setOpen] = React.useState(true)

    useEffect(() => {
        const value = `; ${document.cookie}`
        const parts: Array<any> = value.split(`; cookiesAllowed=`)
        if (parts.length === 2) {
            const cookie = parts.pop().split(';').shift()
            setCookiesAllowed(cookie)
        }
    }, [])

    const registerCookieAcknowledgement = () => {
        document.cookie =
            'cookiesAllowed=true; ' +
            'Path=/; ' +
            'SameSite=Strict; ' +
            'max-age=2147483647;'
        setCookiesAllowed(true)
        setOpen(false)
    }

    return (
        <>
            {!cookiesAllowed && (
                <Drawer
                    title="🍪 Esse aplicativo usa cookies"
                    placement={'bottom'}
                    closable={false}
                    open={open}
                    maskClosable={false}
                    height={330}
                >
                    Usamos somente cookies essenciais para o funcionamento da
                    aplicação, são eles:
                    <ul>
                        <li>
                            <strong>Cookies de sessão</strong>: usado para
                            identificar o usuário logado (tokens de acesso,
                            nível de permissão, nome e email).
                        </li>
                        <li>
                            <strong>
                                Cookies para armazenamento de preferências
                            </strong>
                            : usado para identificar preferências do usuário
                            como o<br />
                            aceite das condições de armazenamento dos cookies e
                            o aceite ou não da instalação do aplicativo para uso
                            offline.
                        </li>
                    </ul>
                    Também no caso de uso offline o banco de dados local do
                    navegador é utilizado para armazenar dados da aplicação:{' '}
                    <br />
                    <ul>
                        <li>
                            <strong>Componentes</strong>: dados de todos
                            componentes cadastrados.
                        </li>
                        <li>
                            <strong>Usinas</strong>: dados das usinas
                            selecionadas pelo usuário para uso offline.
                        </li>
                        <li>
                            <strong>Última sincronização</strong>: data da
                            última sincronização de cada tabela.
                        </li>
                    </ul>
                    <Button
                        type="primary"
                        onClick={registerCookieAcknowledgement}
                    >
                        Prosseguir com os cookies requeridos
                    </Button>
                </Drawer>
            )}
        </>
    )
}

export default PWAInstallPrompt
