import React, { createContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { App } from 'antd'
import { useLocalDb } from './useLocalDb'
import useInterval from './useInterval'

export type httpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

interface AccountContextType {
    authenticate: Function
    logOut: Function
    authenticatedFetch: Function
    getAccessToken: Function
    hasRefreshToken: Function
    getTipoUsuario: Function
    getNomeUsuario: Function
    getEmailUsuario: Function
}

export const AccountContext = createContext<AccountContextType>({
    authenticate: (username: string, password: string, lembrar: boolean) => {},
    logOut: () => {},
    authenticatedFetch: () => {},
    getAccessToken: () => {},
    hasRefreshToken: () => {},
    getTipoUsuario: () => {},
    getNomeUsuario: () => {},
    getEmailUsuario: () => {},
})

const Auth = (props: React.PropsWithChildren) => {
    const { message } = App.useApp()
    const { isOnline } = useLocalDb()
    const navigate = useNavigate()
    const refreshTokenRef = useRef(false)

    useEffect(() => {
        if (hasRefreshToken() && isOnline && !refreshTokenRef.current) {
            refreshToken()
        }

        return () => {
            // React 18 chama useEffect duas vezes durante o desenvolvimento.
            // Esse valor de retorno serve para evitar que refreshToken() seja chamado
            // duas vezes sendo uma delas com o token já invalidado, forçando o usuário a
            // fazer login novamente
            refreshTokenRef.current = true
        }
    }, [])

    useInterval(async () => {
        if (hasRefreshToken() && isOnline) {
            await refreshToken()
        }
    }, 60 * 1000 * 14) // Roda a cada 14 minutos

    const authenticate = (
        username: string,
        password: string,
        lembrar: boolean
    ) => {
        fetch(process.env.REACT_APP_SERVER_IP + '/auth/login', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                email: username,
                senha: password,
                lembrar: lembrar,
            }),
        })
            .then((res) => {
                if (res && res.status === 200) {
                    return res.json().then((data) => {
                        setCookiesInBrowser(
                            data.accessToken,
                            data.accessTokenExpiresIn,
                            data.refreshToken,
                            data.refreshTokenExpiresIn,
                            data.tipoUsuario,
                            data.nome,
                            data.email
                        )
                        navigate('/app/dashboard')
                    })
                } else if (res && res.status === 403) {
                    message.warning(
                        <>
                            Sua conta não está ativa.
                            <br />É necessário solicitar a ativação da sua conta
                            com o administrador para poder fazer o login.
                        </>,
                        6
                    )
                } else {
                    message.error(
                        <>
                            Erro ao tentar fazer o login, verifique suas
                            credenciais e tente novamente
                        </>,
                        6
                    )
                }
            })
            .catch((err) => {
                message.error(
                    'Erro ao contactar o servidor, tente novamente mais tarde',
                    6
                )
            })
    }

    const logOut = () => {
        document.cookie = 'accessToken' + '=; Path=/; max-age=0;'
        document.cookie = 'refreshToken' + '=; Path=/; max-age=0;'
        document.cookie = 'tipoUsuario' + '=; Path=/; max-age=0;'
        document.cookie = 'nomeUsuario' + '=; Path=/; max-age=0;'
        document.cookie = 'emailUsuario' + '=; Path=/; max-age=0;'

        navigate('/login')
    }

    const refreshToken = async () => {
        return await fetch(
            process.env.REACT_APP_SERVER_IP + '/auth/refreshToken',
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify({ refreshToken: getRefreshToken() }),
            }
        )
            .then((res) => {
                if (res && res.status === 200) {
                    return res.json().then((data) => {
                        setCookiesInBrowser(
                            data.accessToken,
                            data.accessTokenExpiresIn,
                            data.refreshToken,
                            data.refreshTokenExpiresIn,
                            data.tipoUsuario,
                            data.nome,
                            data.email
                        )
                    })
                } else {
                    message.error({
                        content: (
                            <>
                                Erro ao tentar atualizar os tokens, faça o login
                                novamente.
                            </>
                        ),
                        duration: 6,
                        key: 'refreshTokenFetchError',
                    })
                    logOut()
                }
            })
            .catch((err) => {
                if (isOnline && hasRefreshToken()) {
                    message.error({
                        key: 'refreshTokenFetchError',
                        content:
                            'Erro ao tentar contactar o servidor para atualizar tokens',
                        duration: 6,
                    })
                }
            })
    }

    const authenticatedFetch = async (
        url: string,
        method: httpMethod,
        body: string
    ) => {
        let accessToken = getAccessToken()

        if (accessToken === null && !hasRefreshToken()) {
            message.warning({
                key: 'sessionExpired',
                content: 'Sua sessão expirou, faça o login novamente',
                duration: 6,
            })
            logOut()
        }

        return fetch(url, {
            method: method,
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
            }),
            body: body,
        })
    }

    const setCookiesInBrowser = (
        accessToken: String,
        accessTokenExpiresIn: number,
        refreshToken: String,
        refreshTokenExpiresIn: number,
        tipoUsuario: String,
        nome: String,
        email: String
    ) => {
        document.cookie =
            'accessToken=' +
            accessToken +
            '; Path=/;' +
            'max-age=' +
            accessTokenExpiresIn / 1000 +
            ';' +
            ' SameSite=Strict;'

        document.cookie =
            'refreshToken=' +
            refreshToken +
            '; Path=/;' +
            'max-age=' +
            refreshTokenExpiresIn / 1000 +
            ';' +
            ' SameSite=Strict;'

        document.cookie =
            'tipoUsuario=' +
            tipoUsuario +
            '; Path=/;' +
            'max-age=' +
            refreshTokenExpiresIn / 1000 +
            ';' +
            ' SameSite=Strict;'

        document.cookie =
            'nomeUsuario=' +
            nome +
            '; Path=/;' +
            'max-age=' +
            refreshTokenExpiresIn / 1000 +
            ';' +
            ' SameSite=Strict;'

        document.cookie =
            'emailUsuario=' +
            email +
            '; Path=/;' +
            'max-age=' +
            refreshTokenExpiresIn / 1000 +
            ';' +
            ' SameSite=Strict;'
    }

    const getAccessToken = () => getCookie('accessToken')

    const getRefreshToken = () => getCookie('refreshToken')

    const getTipoUsuario = () => getCookie('tipoUsuario')

    const getNomeUsuario = () => getCookie('nomeUsuario')

    const getEmailUsuario = () => getCookie('emailUsuario')

    const hasRefreshToken = () => {
        if (getRefreshToken() !== null && getRefreshToken() !== '') {
            return true
        }
        return false
    }

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`
        const parts: Array<any> = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        } else {
            return null
        }
    }

    return (
        <AccountContext.Provider
            value={{
                authenticate,
                logOut,
                authenticatedFetch,
                getAccessToken,
                hasRefreshToken,
                getTipoUsuario,
                getNomeUsuario,
                getEmailUsuario,
            }}
        >
            {props.children}
        </AccountContext.Provider>
    )
}

export default Auth
