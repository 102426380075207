import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Col, Row, Select, Space, Table } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { useNavigate } from 'react-router-dom'
import Search from 'antd/es/input/Search'
import VisualizarComponenteDrawer from './VisualizarComponenteDrawer'
import { AccountContext } from '../../utils/Auth'
import UltimaSincronizacao from '../../components/UltimaSincronizacao'
import { useLocalDb } from '../../utils/useLocalDb'
import usePWAInstall from '../../utils/usePWAInstall'

const Componentes = () => {
    const { message } = App.useApp()
    const [visualizarComponenteOpen, setVisualizarComponenteOpen] =
        useState(false)
    const [componenteId, setComponenteId] = useState(-1)
    const [tipoComponente, setTipoComponente] = useState<
        'inversor' | 'transformador' | 'painel'
    >('inversor')
    const [pagina, setPagina] = useState(0)
    const [totalElementos, setTotalElementos] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [fabricantes, setFabricantes] = useState<String[]>([])
    const [fabricante, setFabricante] = useState<string | undefined>(undefined)
    const [modelo, setModelo] = useState<string>('')
    const [isComponenteModificado, setIsComponenteModificado] = useState(false)

    const [data, setData] = useState<any[]>([])
    const { authenticatedFetch } = useContext(AccountContext)
    const navigate = useNavigate()
    const {
        isOnline,
        syncAllComponentes,
        getFabricantesFromLocalDb,
        getComponentesWithPaginationFromLocalDb,
    } = useLocalDb()
    const { isInstalled } = usePWAInstall()

    useEffect(() => setFabricante(undefined), [tipoComponente])

    useEffect(() => {
        if (tipoComponente !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    tipoComponente +
                    '/fabricantes',
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setFabricantes(data)
                        })
                    }
                })
                .catch(() => {
                    if (isInstalled && !isOnline) {
                        getFabricantesFromLocalDb(tipoComponente).then((data) =>
                            setFabricantes(data)
                        )
                    } else if (isOnline) {
                        message.error({
                            key: 'componentesFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }, [tipoComponente, isInstalled, isOnline])

    const getComponentes = () => {
        let requestUrl =
            process.env.REACT_APP_SERVER_IP +
            '/componentes/' +
            tipoComponente +
            `/listar?pagina=${pagina}`

        if (fabricante && fabricante.length > 0) {
            requestUrl += `&fabricante=${fabricante}`
        }
        if (modelo.length > 0) {
            requestUrl += `&modelo=${modelo}`
        }

        if (tipoComponente !== undefined) {
            authenticatedFetch(requestUrl, 'GET')
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any) => {
                            setData(data.content)
                            setTotalElementos(data.totalElements)
                            setPageSize(data.size)
                        })
                    }
                })
                .catch((e: any) => {
                    if (isInstalled && !isOnline) {
                        getComponentesWithPaginationFromLocalDb(
                            tipoComponente,
                            modelo,
                            fabricante,
                            8,
                            pagina
                        ).then((data) => {
                            setData(data.content)
                            setTotalElementos(data.totalElements)
                            setPageSize(8)
                        })
                    } else if (isOnline) {
                        message.error({
                            key: 'componentesFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }

    useEffect(() => {
        setPagina(0)
    }, [tipoComponente, fabricante, modelo])

    useEffect(() => {
        getComponentes()
    }, [tipoComponente, fabricante, modelo, pagina, isInstalled, isOnline])

    useEffect(() => {
        if (isComponenteModificado) {
            getComponentes()
            setIsComponenteModificado(false)
        }
    }, [isComponenteModificado])

    interface DataType {
        key: string
        id: number
        fabricante: string
        modelo: string
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Fabricante',
            dataIndex: 'fabricante',
            key: 'fabricante',
            ellipsis: true,
        },
        {
            title: 'Modelo',
            dataIndex: 'modelo',
            key: 'modelo',
            ellipsis: true,
        },
        {
            title: 'Ações',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        shape={'circle'}
                        onClick={(e) => {
                            navigate(
                                '/app' +
                                    '/componentes/' +
                                    tipoComponente +
                                    '/' +
                                    record.id
                            )
                            setComponenteId(record.id)
                            setVisualizarComponenteOpen(true)
                        }}
                        icon={<SearchOutlined />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card
                title={'Componentes'}
                extra={
                    <Button
                        type={'primary'}
                        icon={<PlusOutlined />}
                        onClick={(e) => navigate('./novo')}
                    >
                        Adicionar
                    </Button>
                }
                actions={
                    isInstalled
                        ? [
                              <UltimaSincronizacao
                                  tabela={'inversor'}
                                  onSync={syncAllComponentes}
                              />,
                          ]
                        : undefined
                }
            >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Row gutter={[16, 16]}>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 6 }}
                            xl={{ span: 4 }}
                        >
                            <Select
                                defaultValue="inversor"
                                style={{ width: '100%' }}
                                onChange={(
                                    value:
                                        | 'inversor'
                                        | 'transformador'
                                        | 'painel'
                                ) => {
                                    setTipoComponente(value)
                                }}
                                options={[
                                    { value: 'inversor', label: 'Inversores' },
                                    { value: 'painel', label: 'Painéis' },
                                    {
                                        value: 'transformador',
                                        label: 'Transformadores',
                                    },
                                ]}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 6 }}
                            xl={{ span: 4 }}
                        >
                            <Select
                                value={fabricante}
                                onChange={(value) => {
                                    setFabricante(value)
                                }}
                                allowClear={true}
                                style={{ width: '100%' }}
                                showSearch
                                placeholder="Fabricante"
                                options={fabricantes.map((fabricante) => ({
                                    label: fabricante,
                                    value: fabricante,
                                }))}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            xl={{ span: 16 }}
                        >
                            <Search
                                placeholder="Pesquise pelo modelo"
                                onSearch={(e) => setModelo(e)}
                                style={{ width: '100%' }}
                                allowClear
                            />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={(record) => record.id}
                        pagination={{
                            total: totalElementos,
                            pageSize: pageSize,
                            current: pagina + 1,
                            onChange: (page, pageSize) => setPagina(page - 1),
                            showSizeChanger: false,
                        }}
                    />
                </Space>
            </Card>
            <VisualizarComponenteDrawer
                open={visualizarComponenteOpen}
                setOpen={setVisualizarComponenteOpen}
                setComponenteModificado={setIsComponenteModificado}
                tipoComponente={tipoComponente}
                id={componenteId}
            />
        </>
    )
}

Componentes.propTypes = {}

export default Componentes
