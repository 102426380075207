import React from 'react'
import { Card, Col, Row } from 'antd';

const { Meta } = Card;

const gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
};

const Dashboard = () => {
    return <>
        <Card title={'Dashboard'}></Card>
    </>
}

Dashboard.propTypes = {}

export default Dashboard