import React, { useContext } from 'react'
import {
    App,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
} from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import FabricanteWithAddField from '../../components/FabricanteWithAddField'
import { AccountContext } from '../../utils/Auth'
import { useNavigate } from 'react-router-dom'
import usePWAInstall from '../../utils/usePWAInstall'
import { useLocalDb } from '../../utils/useLocalDb'

const NovoComponente = () => {
    const { message } = App.useApp()
    const [tipoComponente, setTipoComponente] = React.useState<
        'inversor' | 'painel' | 'transformador' | undefined
    >(undefined)
    const [form] = Form.useForm()
    const { authenticatedFetch } = useContext(AccountContext)
    const navigate = useNavigate()
    const { isInstalled } = usePWAInstall()
    const { addComponenteToLocalDb } = useLocalDb()

    const onFinish = (values: any) => {
        let newValues = values
        delete newValues.tipo

        if (tipoComponente !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    tipoComponente,
                'POST',
                JSON.stringify(newValues)
            )
                .then((res: { status: number }) => {
                    if (res.status === 201) {
                        message.success('Componente cadastrado com sucesso!', 8)
                        navigate('/app/componentes')
                    } else {
                        message.error(
                            'Erro ao tentar cadastrar o componente',
                            8
                        )
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        addComponenteToLocalDb(tipoComponente, newValues).then(
                            message.success(
                                'Componente cadastrado no banco de dados local com sucesso!',
                                8
                            )
                        )
                        navigate('/app/componentes')
                    } else {
                        message.error('Erro ao tentar contactar o servidor', 8)
                    }
                })
        }
    }

    const caracteristicasInversor = (
        <>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'potencia'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com a potência',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Potência'}
                            addonAfter={'kW'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.List name="configuracoesMPPT">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key}>
                                <Space>
                                    <b>Configuração {index + 1}</b>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    ) : null}
                                </Space>

                                <Row gutter={16}>
                                    <Col
                                        xs={{ span: 24 }}
                                        lg={{ span: 4 }}
                                        xl={{ span: 4 }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'numeroMPPTS']}
                                            label={'Número de MPPTs'}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        lg={{ span: 4 }}
                                        xl={{ span: 4 }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'stringsPorMPPT']}
                                            label="Strings por MPPT"
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Row gutter={16}>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}
                                xl={{ span: 8 }}
                            >
                                <Button
                                    type={'dashed'}
                                    icon={<PlusCircleOutlined />}
                                    style={{ width: '100%' }}
                                    onClick={() => add()}
                                >
                                    Adicionar outra configuração
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>
        </>
    )

    const caracteristicasPainel = (
        <>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'potencia'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com a potência',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Potência'}
                            addonAfter={'Wp'}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    const caracteristicasTransformador = (
        <>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'potencia'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com a potência',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Potência'}
                            addonAfter={'kVA'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'tensaoPrimaria'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a tensão primária',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Tensão primária'}
                            addonAfter={'V'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'tensaoSecundaria'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a tensão secundária',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Tensão secundária'}
                            addonAfter={'V'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                    <Form.Item
                        name={'numeroFases'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o número de fases',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Número de fases'}
                            precision={0}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    const renderCaracteristicasEspecificas = () => {
        switch (tipoComponente) {
            case 'inversor':
                return caracteristicasInversor
            case 'painel':
                return caracteristicasPainel
            case 'transformador':
                return caracteristicasTransformador
            default:
                return null
        }
    }

    return (
        <>
            <Card title={'Novo componente'}>
                <Form
                    form={form}
                    layout={'vertical'}
                    initialValues={{
                        configuracoesMPPT: [
                            { numeroMPPTS: null, stringsPorMPPT: null },
                        ],
                    }}
                    onFinish={onFinish}
                >
                    <b style={{ display: 'block', marginBottom: 8 }}>
                        Características gerais
                    </b>
                    <Row gutter={16}>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 4 }}
                        >
                            <Form.Item name={'tipo'}>
                                <Select
                                    allowClear={true}
                                    placeholder={'Tipo do componente'}
                                    onChange={(value) => {
                                        setTipoComponente(value)
                                    }}
                                    options={[
                                        {
                                            value: 'inversor',
                                            label: 'Inversor',
                                        },
                                        {
                                            value: 'painel',
                                            label: 'Painel',
                                        },
                                        {
                                            value: 'transformador',
                                            label: 'Transformador',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 4 }}
                        >
                            <Form.Item
                                name={'fabricante'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor entre com o fabricante',
                                    },
                                ]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <FabricanteWithAddField
                                    tipoComponente={tipoComponente}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 4 }}
                        >
                            <Form.Item
                                name={'modelo'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor entre com o modelo do componente',
                                    },
                                ]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input
                                    disabled={tipoComponente === undefined}
                                    placeholder={'Modelo'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {tipoComponente !== undefined && (
                        <>
                            <Divider style={{ marginTop: 0 }} />
                            <b style={{ display: 'block', marginBottom: 8 }}>
                                Características do {tipoComponente}
                            </b>
                            {renderCaracteristicasEspecificas()}
                        </>
                    )}

                    <Row justify={'end'}>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 5 }}
                            xl={{ span: 4 }}
                        >
                            <Button
                                style={{
                                    marginLeft: 'auto',
                                    marginTop:
                                        tipoComponente === undefined ? 0 : 24,
                                    width: '100%',
                                }}
                                type={'primary'}
                                disabled={tipoComponente === undefined}
                                htmlType={'submit'}
                            >
                                Adicionar componente
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

NovoComponente.propTypes = {}

export default NovoComponente
