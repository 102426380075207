import React from 'react'
import { useLastSyncTimestamp } from '../utils/useLastSyncTimestamp'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import { Row, Spin, Typography } from 'antd'
import { useLocalDb } from '../utils/useLocalDb'

type Props = {
    tabela: string
    onSync: () => void
}
const UltimaSincronizacao = (props: Props) => {
    const lastOfflineSync = useLastSyncTimestamp(props.tabela)
    const { isSyncing } = useLocalDb()

    const handleSync = () => {
        props.onSync()
    }

    const antSpinIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />

    return (
        <>
            {isSyncing ? (
                <Row
                    justify={'center'}
                    onClick={handleSync}
                    className={'ultima-sincronizacao-barra'}
                >
                    <Typography.Text
                        type={'secondary'}
                        style={{ marginRight: 8 }}
                    >
                        Sincronizando...
                    </Typography.Text>
                    <Spin indicator={antSpinIcon} />
                </Row>
            ) : (
                <Row
                    justify={'center'}
                    onClick={handleSync}
                    style={{ cursor: 'pointer' }}
                    className={'ultima-sincronizacao-barra'}
                >
                    <Typography.Text
                        type={'secondary'}
                        style={{ marginRight: 8 }}
                    >
                        Última sincronização {lastOfflineSync}
                    </Typography.Text>
                    <SyncOutlined />
                </Row>
            )}
        </>
    )
}

export default UltimaSincronizacao
