import React from 'react'
import { Button, Col, Form, Row } from 'antd'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    formInstance: any
    formType: string
}

const FormStep5 = (props: Props) => {
    const loadedQuestions = questions();

    let proprietarioInstruidoLimpezaPaineis = Form.useWatch(
        'proprietarioInstruidoLimpezaPaineis',
        props.formInstance
    )

    return (
        <>
            <Row>
                <Col md={25} lg={18} xl={14} xxl={10}>
                    <QuestionWrapper
                        question={loadedQuestions.q41}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q42}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q43}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q44}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q45}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q46}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q47}
                        formType={props.formType}
                    />
                    {proprietarioInstruidoLimpezaPaineis === true && (
                        <QuestionWrapper
                            question={loadedQuestions.q48}
                            formType={props.formType}
                        />
                    )}
                    <QuestionWrapper
                        question={loadedQuestions.q49}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q50}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q51}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q52}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q53}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q54}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q55}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q56}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q57}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q58}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q59}
                        formType={props.formType}
                    />

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                    onClick={(e) =>
                                        props.handleStepChange(
                                            props.currentStep + 1
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep5
