import React, { useState } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd'
import ComponenteSelect from './ComponenteSelect/ComponenteSelect'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    tipoUsina: string
    formInstance: any
    formType: string
}

const FormStep1 = (props: Props) => {
    const [quantidadeTotalModulos, setQuantidadeTotalModulos] = useState<number>(0);
    const [potenciaTotalCC, setPotenciaTotalCC] = useState<number>(0);

    const loadedQuestions = questions();

    return (
        <>
                <Row>
                    <Col md={24} lg={18} xl={14} xxl={10}>
                        <ComponenteSelect
                            tipoComponente={'painel'}
                            formInstance={props.formInstance}
                            formType={props.formType}
                            setQuantidadeTotal={setQuantidadeTotalModulos}
                            setPotenciaTotal={setPotenciaTotalCC}
                        />

                        <Divider />

                        <QuestionWrapper
                            question={{ ...loadedQuestions.q8, value: quantidadeTotalModulos }}
                            formType={props.formType}
                        />

                        <QuestionWrapper
                            question={{ ...loadedQuestions.q12, value: potenciaTotalCC }}
                            formType={props.formType}
                        />

                        {props.tipoUsina === 'COBERTURA' && (
                            <QuestionWrapper
                                question={loadedQuestions.q13}
                                formType={props.formType}
                            />
                        )}
                        <QuestionWrapper
                            question={loadedQuestions.q14}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q15}
                            formType={props.formType}
                        />
                        {props.tipoUsina === 'COBERTURA' && (
                            <Form.List name={'anguloPaineisExtras'}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={field.key}>
                                                <Button
                                                    icon={<MinusCircleOutlined />}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    style={{
                                                        marginBottom: 8,
                                                        width: '100%',
                                                    }}
                                                >
                                                    Remover inclinação {index + 2}
                                                </Button>
                                                <QuestionWrapper
                                                    question={{
                                                        ...loadedQuestions.q14,
                                                        field_name: [
                                                            field.name,
                                                            loadedQuestions.q14
                                                                .field_name,
                                                        ],
                                                        renderFIObs: false,
                                                    }}
                                                    formType={props.formType}
                                                />
                                                <QuestionWrapper
                                                    question={{
                                                        ...loadedQuestions.q15,
                                                        field_name: [
                                                            field.name,
                                                            loadedQuestions.q15
                                                                .field_name,
                                                        ],
                                                        renderFIObs: false,
                                                    }}
                                                    formType={props.formType}
                                                />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Adicionar outras inclinações
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        )}
                        <QuestionWrapper
                            question={loadedQuestions.q16}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q17}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q18}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q19}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q20}
                            formType={props.formType}
                        />
                        <QuestionWrapper
                            question={loadedQuestions.q21}
                            formType={props.formType}
                        />

                        {props.tipoUsina === 'COBERTURA' && <></>}
                        {props.tipoUsina === 'SOLO' && <></>}

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                    onClick={(e) =>
                                        props.handleStepChange(
                                            props.currentStep + 1,
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep1
