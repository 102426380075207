import React, { useContext, useEffect, useState } from 'react'
import { AccountContext } from './Auth'
import { db, Inspecao, Inversor, Painel, Transformador, Usina } from '../db/db'
import { App } from 'antd'

export const useLocalDb = (): ReturnType => {
    const { message } = App.useApp()
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const [isSyncing, setIsSyncing] = useState(false)
    const { authenticatedFetch } = useContext(AccountContext)
    const currentDate = new Date()

    useEffect(() => {
        function onlineHandler() {
            setIsOnline(true)
        }

        function offlineHandler() {
            setIsOnline(false)
        }

        window.addEventListener('online', onlineHandler)
        window.addEventListener('offline', offlineHandler)

        return () => {
            window.removeEventListener('online', onlineHandler)
            window.removeEventListener('offline', offlineHandler)
        }
    }, [])

    const syncEverything = async () => {
        setIsSyncing(true)
        await syncAllComponentes()
        await syncUsinas()
        await syncInspecoes()
        setIsSyncing(false)
    }

    const syncUsinas = async () => {
        setIsSyncing(true)
        await sendUsinasToServer()
        await getDataFromServer('/usinas/listarUsinas', 'usina', 100, true)
        setIsSyncing(false)
    }

    const syncInspecoes = async () => {
        setIsSyncing(true)
        await sendInspecoesToServer()
        setIsSyncing(false)
    }

    const syncAllComponentes = async () => {
        setIsSyncing(true)
        await sendComponentesToServer()
        await getDataFromServer(
            '/componentes/inversor/listar',
            'inversor',
            100,
            true
        )
        await getDataFromServer(
            '/componentes/painel/listar',
            'painel',
            100,
            true
        )
        await getDataFromServer(
            '/componentes/transformador/listar',
            'transformador',
            100,
            true
        )
        await getDataFromServer('/usinas/listarUsinas', 'usina', 100, true)
        setIsSyncing(false)
    }

    const sendUsinasToServer = async () => {
        const epoch = new Date(0)

        const usinas = await db.usina
            .where('ultimaModificacao')
            .equals(epoch)
            .and((t) => t.deletado === 0)
            .toArray()

        for (const usina of usinas) {
            const body = { ...usina }

            if (body.deletado === 1) {
                body.deletado = true
            } else {
                body.deletado = false
            }

            const res = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usinas',
                'POST',
                JSON.stringify(body)
            )

            const usinaCadastrada = await res.json()

            if (res && res.ok && res.status === 201 && usina.id) {
                await db.usina.delete(usina.id)

                if (usina.idOffline) {
                    let inspecoes = await db.inspecao
                        .where('idUsinaOffline')
                        .equals(usina.idOffline)
                        .toArray()

                    for (const inspecao of inspecoes) {
                        await authenticatedFetch(
                            process.env.REACT_APP_SERVER_IP +
                                `/inspecoes?idUsina=${usinaCadastrada.id}`,
                            'POST',
                            JSON.stringify(body)
                        )

                        if (inspecao.id) await db.inspecao.delete(inspecao.id)
                    }
                }
            }
        }
    }

    const uploadFotos = async (fotos: any[], idUsina: string | number) => {
        const numFotos = fotos.length

        const fotosKeys: any[] = []

        await Promise.all(
            fotos.map(async (e: any, idx: number) => {
                const uploadUrlRes = await authenticatedFetch(
                    process.env.REACT_APP_SERVER_IP +
                        '/inspecoes/imagemUploadUrl?idUsina=' +
                        idUsina +
                        '&fileName=' +
                        e.name,
                    'GET'
                )
                if (!uploadUrlRes.ok)
                    throw new Error('Erro ao tentar enviar as fotos')

                const uploadUrl = await uploadUrlRes.json()

                const uploadRes = await fetch(uploadUrl.url, {
                    method: 'PUT',
                    body: e.originFileObj,
                    headers: { 'Content-Type': 'image/*' },
                })

                if (!uploadRes.ok)
                    throw new Error('Erro ao tentar enviar as fotos')
                fotosKeys.push(uploadUrl.key)
                return
            })
        )

        return fotosKeys
    }

    const sendInspecoesToServer = async () => {
        const lastSync = await db.lastSync
            .where('tableName')
            .equals('inspecao')
            .toArray()

        let lastSyncDate = new Date().toISOString()

        const epoch = new Date(0)

        const inspecoes = await db.inspecao
            .where('ultimaModificacao')
            .equals(epoch)
            .toArray()

        for (const inspecao of inspecoes) {
            const body = { ...inspecao }

            // Upload de imagens para o S3
            if (body.fotos && body.fotos.length > 0) {
                let fotosKeys
                try {
                    fotosKeys = await uploadFotos(
                        body.fotos,
                        body.idUsinaOffline
                    )
                } catch (e) {
                    continue
                }

                body.fotos = fotosKeys
            }

            const res = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    `/inspecoes?idUsina=${body.idUsinaOffline}`,
                'POST',
                JSON.stringify(body)
            )

            if (res && res.ok && res.status === 201 && inspecao.id) {
                await db.inspecao.delete(inspecao.id)
            }
        }

        if (lastSync.length > 0 && lastSync[0].id) {
            await db.lastSync.update(lastSync[0].id, {
                tableName: 'inspecao',
                lastSync: currentDate,
            })
        } else {
            await db.lastSync.add({
                tableName: 'inspecao',
                lastSync: currentDate,
            })
        }
    }

    // Remove somente os componentes que ainda não foram enviados para o servidor
    const removeComponenteFromLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        id: number
    ) => {
        const epoch = new Date(0)
        const componente = await db[tipoComponente].get(id)

        if (componente && componente.ultimaModificacao === epoch) {
            await db[tipoComponente].delete(id)
        }
    }

    const getDataFromServer = async (
        url: string,
        localTableName: 'inversor' | 'painel' | 'transformador' | 'usina',
        pageSize: number,
        force: boolean = false
    ) => {
        const lastSync = await db.lastSync
            .where('tableName')
            .equals(localTableName)
            .toArray()

        let lastSyncDate = new Date(0).toISOString()
        let page = 0
        let lastPage = false

        // Se a última sincronização foi feita há menos de 1 hora, aborta
        if (
            !force &&
            lastSync.length > 0 &&
            lastSync[0].lastSync &&
            Math.abs(currentDate.getTime() - lastSync[0].lastSync.getTime()) <
                1000 * 60 * 60
        ) {
            return
        }

        if (lastSync[0] && lastSync[0].lastSync) {
            lastSyncDate = lastSync[0].lastSync.toISOString()
        }

        while (!lastPage) {
            try {
                const response = await authenticatedFetch(
                    process.env.REACT_APP_SERVER_IP +
                        url +
                        `?pagina=${page}` +
                        `&tamanhoPagina=${pageSize}` +
                        `&incluirDeletados=true` +
                        `&dataInicial=${lastSyncDate}`,
                    'GET'
                )

                if (response.ok) {
                    const json = await response.json()
                    if (json.content === undefined)
                        throw new Error('Resposta inesperada do servidor')

                    if (json.last === true) {
                        lastPage = true
                    }

                    // Converte o campo deletado para 1 ou 0, já que o IndexedDB não
                    // suporta indexação de campos booleanos
                    json.content.forEach((e: any) => {
                        e.deletado = e.deletado ? 1 : 0
                    })

                    //@ts-ignore
                    await db[localTableName].bulkPut(json.content)
                }
            } catch (e: any) {
                // Durante a primeira inserção, pode ocorrer um erro de constraint
                // ao tentar inserir um registro em last_sync durante o desenvolvimento.
                if (
                    !(
                        e.name &&
                        e.name === 'ConstraintError' &&
                        e.message &&
                        typeof e.message === 'string' &&
                        e.message.includes('tableName')
                    )
                ) {
                    message.warning({
                        content:
                            'Erro ao tentar sincronizar os dados para uso offline',
                        duration: 8,
                        key: 'syncLocalDb',
                    })
                    lastPage = true
                    return
                }
            }

            page++
        }

        if (lastSync.length > 0 && lastSync[0].id) {
            await db.lastSync.update(lastSync[0].id, {
                tableName: localTableName,
                lastSync: currentDate,
            })
        } else {
            await db.lastSync.add({
                tableName: localTableName,
                lastSync: currentDate,
            })
        }
    }

    const sendComponentesToServer = async () => {
        const epoch = new Date(0)

        const inversores = await db.inversor
            .where('ultimaModificacao')
            .equals(epoch)
            .and((t) => t.deletado === 0)
            .toArray()

        const paineis = await db.painel
            .where('ultimaModificacao')
            .equals(epoch)
            .and((t) => t.deletado === 0)
            .toArray()

        const transformadores = await db.transformador
            .where('ultimaModificacao')
            .equals(epoch)
            .and((t) => t.deletado === 0)
            .toArray()

        for (const inversor of inversores) {
            const body = { ...inversor }
            delete body.id

            if (body.deletado === 1) {
                body.deletado = true
            } else {
                body.deletado = false
            }

            const res = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/componentes/inversor',
                'POST',
                JSON.stringify(body)
            )

            if (res && res.ok && res.status === 201 && inversor.id) {
                await db.inversor.delete(inversor.id)
            }
        }

        for (const painel of paineis) {
            const body = { ...painel }
            delete body.id

            const res = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/componentes/painel',
                'POST',
                JSON.stringify(body)
            )

            if (res && res.ok && res.status === 201 && painel.id) {
                await db.inversor.delete(painel.id)
            }
        }

        for (const transformador of transformadores) {
            const body = { ...transformador }
            delete body.id

            const res = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/componentes/transformador',
                'POST',
                JSON.stringify(body)
            )

            if (res && res.ok && res.status === 201 && transformador.id) {
                await db.inversor.delete(transformador.id)
            }
        }
    }

    const getComponentesWithPaginationFromLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        modelo: string,
        fabricante: string | undefined,
        tamanhoPagina: number,
        pagina: number
    ) => {
        let total
        let componentes

        if (!fabricante) {
            total = await db[tipoComponente]
                .filter((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .count()

            componentes = await db[tipoComponente]
                .filter((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .offset(tamanhoPagina * pagina)
                .limit(tamanhoPagina)
                .toArray()
        } else {
            total = await db[tipoComponente]
                .where('fabricante')
                .equals(fabricante)
                .and((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .count()

            componentes = await db[tipoComponente]
                .where('fabricante')
                .equals(fabricante)
                .and((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .offset(tamanhoPagina * pagina)
                .limit(tamanhoPagina)
                .toArray()
        }

        return { content: componentes, totalElements: total }
    }

    const getComponentesFromLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        modelo: string,
        fabricante: string | undefined
    ) => {
        let componentes = []

        if (!fabricante) {
            componentes = await db[tipoComponente]
                .filter((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .toArray()
        } else {
            componentes = await db[tipoComponente]
                .where('fabricante')
                .equals(fabricante)
                .and((componente) => {
                    const lowerCaseResponseModelo =
                        componente.modelo.toLowerCase()
                    const lowerCaseModeloParam = modelo.toLowerCase()

                    return (
                        componente.deletado === 0 &&
                        lowerCaseResponseModelo.includes(lowerCaseModeloParam)
                    )
                })
                .toArray()
        }

        return componentes
    }

    const getComponenteFromLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        id: number
    ) => {
        const componente = await db[tipoComponente]
            .where('id')
            .equals(id)
            .and((componente) => {
                return componente.deletado === 0
            })
            .toArray()

        return componente[0]
    }

    const addComponenteToLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        body: Inversor | Painel | Transformador
    ) => {
        body.ultimaModificacao = new Date(0)
        body.deletado = 0
        // O tipo do body é garantido pelo tipo do componente
        //@ts-ignore
        await db[tipoComponente].add(body)
    }

    const getFabricantesFromLocalDb = async (
        tipoComponente: 'inversor' | 'painel' | 'transformador'
    ) => {
        const fabricantes = await db[tipoComponente]
            .orderBy('fabricante')
            .uniqueKeys()

        return fabricantes
    }

    const addUsinaToLocalDb = async (body: Usina) => {
        body.ultimaModificacao = new Date(0)
        body.deletado = 0

        await db['usina'].add(body)
    }

    const getUsinaFromLocalDb = async (id: number) => {
        const usina = await db['usina']
            .where('id')
            .equals(id)
            .and((usina) => {
                return usina.deletado === 0
            })
            .toArray()

        return usina[0]
    }

    const getUsinasWithPaginationFromLocalDb = async (
        regionalIdr: string | undefined,
        cidade: string | undefined,
        nome: string | undefined,
        tamanhoPagina: number,
        pagina: number
    ) => {
        // Se não for passado um parâmetro tratar como string vazia
        // para que o .includes funcione
        regionalIdr = regionalIdr === undefined ? '' : regionalIdr
        cidade = cidade === undefined ? '' : cidade
        nome = nome === undefined ? '' : nome

        let usinas

        let total = await db['usina']
            .filter((usina) => {
                const lowerCaseCidadeRes = usina.cidade.toLowerCase()
                // Cidade nunca é undefined
                // @ts-ignore
                const lowerCaseCidadeParam = cidade.toLowerCase()

                const lowerCaseNomeRes = usina.nome.toLowerCase()
                // Nome nunca é undefined
                // @ts-ignore
                const lowerCaseNomeParam = nome.toLowerCase()

                const regionalIdrRes = usina.regionalIdr

                return (
                    usina.deletado === 0 &&
                    // regionalIdr nunca é undefined
                    // @ts-ignore
                    regionalIdrRes.includes(regionalIdr) &&
                    lowerCaseCidadeRes.includes(lowerCaseCidadeParam) &&
                    lowerCaseNomeRes.includes(lowerCaseNomeParam)
                )
            })
            .count()

        usinas = await db['usina']
            .filter((usina) => {
                const lowerCaseCidadeRes = usina.cidade.toLowerCase()
                // Cidade nunca é undefined
                // @ts-ignore
                const lowerCaseCidadeParam = cidade.toLowerCase()

                const lowerCaseNomeRes = usina.nome.toLowerCase()
                // Nome nunca é undefined
                // @ts-ignore
                const lowerCaseNomeParam = nome.toLowerCase()

                const regionalIdrRes = usina.regionalIdr

                return (
                    usina.deletado === 0 &&
                    // regionalIdr nunca é undefined
                    // @ts-ignore
                    regionalIdrRes.includes(regionalIdr) &&
                    lowerCaseCidadeRes.includes(lowerCaseCidadeParam) &&
                    lowerCaseNomeRes.includes(lowerCaseNomeParam)
                )
            })
            .offset(tamanhoPagina * pagina)
            .limit(tamanhoPagina)
            .toArray()

        return { content: usinas, totalElements: total }
    }

    const addInspecaoToLocalDb = async (idUsina: number, body: Inspecao) => {
        let usina = await getUsinaFromLocalDb(idUsina)

        if (usina.idOffline !== undefined) {
            body.idUsinaOffline = usina.idOffline
        } else if (usina.id !== undefined) {
            body.idUsinaOffline = usina.id
        }

        body.ultimaModificacao = new Date(0)
        body.dataCriacao = new Date().toISOString()

        await db['inspecao'].add(body)
    }

    const getInspecoesWithPaginationFromLocalDb = async (
        idUsina: number,
        tamanhoPagina: number,
        pagina: number
    ) => {
        let usina = await getUsinaFromLocalDb(idUsina)
        let idUsinaOffline: string | number = -1

        if (usina.idOffline !== undefined) {
            idUsinaOffline = usina.idOffline
        } else if (usina.id !== undefined) {
            idUsinaOffline = usina.id
        }

        let inspecao

        let total = await db['inspecao'].count()

        inspecao = await db['inspecao']
            .where('idUsinaOffline')
            .equals(idUsinaOffline)
            .offset(tamanhoPagina * pagina)
            .limit(tamanhoPagina)
            .toArray()

        return { content: inspecao, totalElements: total }
    }

    return {
        isSyncing,
        isOnline,
        syncEverything,
        syncUsinas,
        syncAllComponentes,
        syncInspecoes,
        getComponentesFromLocalDb,
        getComponentesWithPaginationFromLocalDb,
        getComponenteFromLocalDb,
        addComponenteToLocalDb,
        getFabricantesFromLocalDb,
        addUsinaToLocalDb,
        getUsinaFromLocalDb,
        getUsinasWithPaginationFromLocalDb,
        addInspecaoToLocalDb,
        getInspecoesWithPaginationFromLocalDb,
    }
}

export interface ReturnType {
    isSyncing: boolean
    isOnline: boolean
    syncEverything: () => Promise<void>
    syncUsinas: () => Promise<void>
    syncInspecoes: () => Promise<void>
    syncAllComponentes: () => Promise<void>
    getComponentesFromLocalDb: (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        modelo: string,
        fabricante: string | undefined
    ) => Promise<Inversor[] | Painel[] | Transformador[]>
    getComponentesWithPaginationFromLocalDb: (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        modelo: string,
        fabricante: string | undefined,
        tamanhoPagina: number,
        pagina: number
    ) => Promise<{
        content: Inversor[] | Painel[] | Transformador[]
        totalElements: number
    }>
    getComponenteFromLocalDb: (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        id: number
    ) => Promise<any>
    addComponenteToLocalDb: (
        tipoComponente: 'inversor' | 'painel' | 'transformador',
        body: Inversor | Painel | Transformador
    ) => Promise<void>
    getFabricantesFromLocalDb: (
        tipoComponente: 'inversor' | 'painel' | 'transformador'
    ) => Promise<any[]>
    addUsinaToLocalDb: (body: Usina) => Promise<void>
    getUsinaFromLocalDb: (id: number) => Promise<any>
    getUsinasWithPaginationFromLocalDb: (
        regionalIdr: string | undefined,
        cidade: string | undefined,
        nome: string | undefined,
        tamanhoPagina: number,
        pagina: number
    ) => Promise<{ content: Usina[]; totalElements: number }>
    addInspecaoToLocalDb: (idUsina: number, body: Inspecao) => Promise<void>
    getInspecoesWithPaginationFromLocalDb: (
        idUsina: number,
        tamanhoPagina: number,
        pagina: number
    ) => Promise<{ content: Inspecao[]; totalElements: number }>
}
