import { useEffect, useState } from 'react'
import { db } from '../db/db'
import { useLiveQuery } from 'dexie-react-hooks'

export function useLastSyncTimestamp(tabela: string) {
    const [lastSyncTimestamp, setLastSyncTimestamp] = useState('nunca')

    const lastSyncFetchFromDb = useLiveQuery(() =>
        db.lastSync.where('tableName').equals(tabela).toArray()
    )

    useEffect(() => {
        if (lastSyncFetchFromDb && lastSyncFetchFromDb.length > 0) {
            const timeStamp = lastSyncFetchFromDb[0].lastSync.toLocaleString(
                'pt-BR',
                {
                    timeZone: 'America/Sao_Paulo',
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                }
            )
            setLastSyncTimestamp(timeStamp)
        }
    }, [lastSyncFetchFromDb])

    return lastSyncTimestamp
}
