import React, { useContext, useEffect, useState } from 'react'
import {
    Alert,
    Badge,
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space,
    Switch,
    Table,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import Search from 'antd/es/input/Search'
import { AccountContext } from '../../utils/Auth'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { useLocation, useNavigate } from 'react-router-dom'

const Usuarios = () => {
    const [data, setData] = useState<any[]>([])
    const { authenticatedFetch } = useContext(AccountContext)
    const { width } = useWindowDimensions()
    const [pagina, setPagina] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [totalElementos, setTotalElementos] = useState(0)
    const [tipoUsuario, setTipoUsuario] = useState<undefined | string>(
        undefined
    )
    const [email, setEmail] = useState('')

    const [usuarioData, setUsuarioData] = useState<any>({})
    const [isModificarUsuarioOpen, setModificarUsuarioOpen] = useState(false)

    const [modificarUsuarioForm] = Form.useForm()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (
            usuarioData.id &&
            location.pathname !== '/app/administracao/usuario/' + usuarioData.id
        ) {
            setModificarUsuarioOpen(false)
        }
    }, [isModificarUsuarioOpen, location])

    useEffect(() => {
        getData()
    }, [email, tipoUsuario, pagina])

    useEffect(() => {
        if (usuarioData.id) {
            modificarUsuarioForm.setFieldsValue({
                nome: usuarioData.nome,
                email: usuarioData.email,
                ativo: usuarioData.ativo,
                tipoUsuario: usuarioData.tipoUsuario,
            })
        }
    }, [usuarioData])

    const getData = () => {
        let requestUrl =
            process.env.REACT_APP_SERVER_IP +
            '/usuario/listarUsuariosAdmin' +
            `?pagina=${pagina}`

        if (tipoUsuario && tipoUsuario.length > 0) {
            requestUrl += `&tipoUsuario=${tipoUsuario}`
        }

        if (email && email.length > 0) {
            requestUrl += `&email=${email}`
        }

        authenticatedFetch(requestUrl, 'GET')
            .then((res: { ok: any; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    res.json().then((data: any) => {
                        setData(data.content)
                        setTotalElementos(data.totalElements)
                        setPageSize(data.size)
                    })
                }
            })
            .catch(() => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    const onFinishModificarUsuario = (values: any) => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usuario/modificarUsuarioAdmin?idUsuario=' +
                usuarioData.id,
            'PATCH',
            JSON.stringify({
                nome: values.nome,
                email: values.email,
                ativo: values.ativo,
                tipoUsuario: values.tipoUsuario,
            })
        )
            .then((res: { ok: any }) => {
                if (res.ok) {
                    message.success('Usuário modificado com sucesso', 8)
                    setModificarUsuarioOpen(false)
                    navigate(-1)
                    getData()
                } else {
                    message.error('Erro ao modificar usuário', 8)
                }
            })
            .catch(() => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    interface DataType {
        key: string
        id: number
        nome: string
        email: string
        ativo: boolean
        tipoUsuario: string
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
            responsive: ['lg'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'ativo',
            key: 'ativo',
            responsive: ['lg'],
            render: (_, record) =>
                record.ativo ? (
                    <Badge status="success" text={'Ativo'} />
                ) : (
                    <Badge status="error" text={'Inativo'} />
                ),
        },
        {
            title: 'Tipo de usuário',
            dataIndex: 'tipoUsuario',
            responsive: ['lg'],
            key: 'tipoUsuario',
            render: (_, record) => {
                switch (record.tipoUsuario) {
                    case 'ADMIN':
                        return 'Administrador'
                    case 'USUARIO':
                        return 'Usuário comum'
                    default:
                        return 'Desconhecido'
                }
            },
        },
        {
            title: 'Ações',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        shape={'circle'}
                        onClick={(e) => {
                            setUsuarioData(record)
                            setModificarUsuarioOpen(true)
                            navigate('/app/administracao/usuario/' + record.id)
                        }}
                        icon={<EditOutlined />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                        <Select
                            value={tipoUsuario}
                            onChange={(value) => {
                                setTipoUsuario(value)
                                setPagina(0)
                            }}
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Tipo de usuário"
                            options={[
                                {
                                    value: 'ADMIN',
                                    label: 'Administrador',
                                },
                                {
                                    value: 'USUARIO',
                                    label: 'Usuário comum',
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 18 }} xl={{ span: 20 }}>
                        <Search
                            placeholder="Pesquise pelo email"
                            onSearch={(e) => {
                                setEmail(e)
                                setPagina(0)
                            }}
                            style={{ width: '100%' }}
                            allowClear
                        />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    pagination={{
                        total: totalElementos,
                        pageSize: pageSize,
                        current: pagina + 1,
                        onChange: (page, pageSize) => setPagina(page - 1),
                        showSizeChanger: false,
                    }}
                />
            </Space>
            <Drawer
                className={'button-drawer'}
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                title="Modificar usuário"
                closable={true}
                onClose={(e) => {
                    setUsuarioData({})
                    setModificarUsuarioOpen(false)
                    navigate(-1)
                }}
                open={isModificarUsuarioOpen}
                extra={
                    <Space>
                        <Button
                            size={'small'}
                            onClick={(e) => {
                                setUsuarioData({})
                                setModificarUsuarioOpen(false)
                                navigate(-1)
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            size={'small'}
                            onClick={(e) => {
                                modificarUsuarioForm.submit()
                            }}
                            type={'primary'}
                        >
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={modificarUsuarioForm}
                    name="modificarSenha"
                    onFinish={(values) => onFinishModificarUsuario(values)}
                >
                    <Form.Item
                        name="nome"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com o nome',
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com o e-mail',
                            },
                        ]}
                    >
                        <Input placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item>
                        <Alert
                            message={
                                'Tornar um usuário administrador o faz adquirir ' +
                                'total controle sobre o sistema, podendo inclusive adicionar ' +
                                'e remover outros administradores.'
                            }
                            type="warning"
                            showIcon
                        />
                    </Form.Item>
                    <Form.Item
                        name={'tipoUsuario'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o tipo de usuário',
                            },
                        ]}
                    >
                        <Select
                            value={tipoUsuario}
                            //allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Tipo de usuário"
                            options={[
                                {
                                    value: 'ADMIN',
                                    label: 'Administrador',
                                },
                                {
                                    value: 'USUARIO',
                                    label: 'Usuário comum',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ativo"
                        label="Status da conta"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="Ativa"
                            unCheckedChildren="Inativa"
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

Usuarios.propTypes = {}

export default Usuarios
