import React, { useState, useEffect } from 'react'
import { Button, Col, Divider, Form, Row } from 'antd'
import ComponenteSelect from './ComponenteSelect/ComponenteSelect'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    formInstance: any
    formType: string
}

const FormStep2 = (props: Props) => {
    const [quantidadeTotalInversores, setQuantidadeTotalInversores] = useState<number>(0);
    const [potenciaTotalCA, setPotenciaTotalCA] = useState<number>(0);

    const loadedQuestions = questions();

    return (
        <>
            <Row>
                <Col md={24} lg={18} xl={14} xxl={10}>
                    <ComponenteSelect
                        tipoComponente={'inversor'}
                        formInstance={props.formInstance}
                        formType={props.formType}
                        setQuantidadeTotal={setQuantidadeTotalInversores}
                        setPotenciaTotal={setPotenciaTotalCA}
                    />
                    <Divider />

                    <QuestionWrapper
                        question={{ ...loadedQuestions.q22, value: quantidadeTotalInversores }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q26, value: potenciaTotalCA }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q27}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q28}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={loadedQuestions.q29}
                        formType={props.formType}
                    />

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                    onClick={(e) =>
                                        props.handleStepChange(
                                            props.currentStep + 1
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep2
