import React, { useContext, useEffect, useState } from 'react'
import {
    ApartmentOutlined,
    AuditOutlined,
    DashboardOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ThunderboltOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Layout, Menu, MenuProps, theme } from 'antd'
import NapiLogo from '../images/napi_logo_horizontal.svg'
import NapiLogoIcone from '../images/logo_icone.svg'
import NapiLogoWhite from '../images/napi_logo_horizontal_white.svg'
import useWindowDimensions from '../utils/useWindowDimensions'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { AccountContext } from '../utils/Auth'
import { useLocalDb } from '../utils/useLocalDb'
import FloatNavMenu from '../components/FloatNavMenu'
import usePWAInstall from '../utils/usePWAInstall'
import useInterval from '../utils/useInterval'

const { Header, Sider, Content } = Layout

const GlobalLayout = () => {
    const { logOut, getNomeUsuario, getTipoUsuario } =
        useContext(AccountContext)
    const { width, isMobileLayout } = useWindowDimensions()
    const [collapsed, setCollapsed] = useState(false)
    const [menuItems, setMenuItems] = useState<MenuProps['items']>([])
    const [selectedMenuKey, setSelectedMenuKey] = useState('')
    const location = useLocation()
    const { syncEverything, isOnline } = useLocalDb()
    const { isInstalled } = usePWAInstall()

    useEffect(() => {
        syncData()
    }, [isInstalled, isOnline])

    useInterval(() => {
        syncData()
    }, 60 * 1000 * 15) // Roda a cada 15 minutos

    const syncData = () => {
        if (isInstalled && isOnline) {
            syncEverything()
        }
    }

    useEffect(() => {
        let items = [
            {
                key: 'dashboard',
                icon: <DashboardOutlined />,
                label: isOnline ? (
                    <Link to="/app/dashboard">Dashboard</Link>
                ) : (
                    <span>Dashboard</span>
                ),
                title: '',
                disabled: !isOnline,
            },
            {
                key: 'usinas',
                icon: <ThunderboltOutlined />,
                label: <Link to="/app/usinas">Usinas</Link>,
                title: '',
            },
            {
                key: 'componentes',
                icon: <ApartmentOutlined />,
                label: <Link to="/app/componentes">Componentes</Link>,
                title: '',
            },
        ]

        if (getTipoUsuario() === 'ADMIN') {
            items.push({
                key: 'administracao',
                icon: <AuditOutlined />,
                label: isOnline ? (
                    <Link to="/app/administracao">Administração</Link>
                ) : (
                    <span>Administração</span>
                ),
                title: '',
                disabled: !isOnline,
            })
        }

        setMenuItems(items)
    }, [isOnline])

    const {
        token: { colorBgContainer },
    } = theme.useToken()

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <Link to="/app/minhaconta">Minha conta</Link>,
        },
        {
            key: '2',
            label: (
                <a href="#" onClick={(e) => logOut()}>
                    Sair
                </a>
            ),
        },
    ]

    useEffect(() => {
        switch (location.pathname) {
            case '/app/dashboard':
                setSelectedMenuKey('dashboard')
                break
            case '/app/usinas':
                setSelectedMenuKey('usinas')
                break
            case '/app/componentes':
                setSelectedMenuKey('componentes')
                break
            case '/app/administracao':
                setSelectedMenuKey('administracao')
                break
            default:
                setSelectedMenuKey('')
        }
    }, [location])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {isMobileLayout ? (
                <FloatNavMenu items={menuItems} selectedKey={selectedMenuKey} />
            ) : (
                <Sider
                    theme="light"
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width={width < 500 ? width : 200}
                    collapsedWidth={width < 500 ? 50 : 80}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'sticky',
                        top: 0,
                        left: 0,
                    }}
                >
                    <div className="dashboard-logo">
                        <img
                            src={collapsed ? NapiLogoIcone : NapiLogo}
                            alt={'Logo do NAPI Solar'}
                        />
                        {width < 500 &&
                            !collapsed &&
                            React.createElement(
                                collapsed
                                    ? MenuUnfoldOutlined
                                    : MenuFoldOutlined,
                                {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                }
                            )}
                    </div>

                    <Menu
                        theme="light"
                        mode="inline"
                        selectedKeys={[selectedMenuKey]}
                        items={menuItems}
                    />
                </Sider>
            )}

            <Layout
                className="site-layout"
                style={!collapsed && width < 500 ? { overflow: 'hidden' } : {}}
            >
                <Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 16,
                        background: colorBgContainer,
                        height: isMobileLayout ? 84 : 64,
                        lineHeight: isMobileLayout ? '84px' : '64px',
                    }}
                >
                    {isMobileLayout ? (
                        <img
                            height={40}
                            src={NapiLogoWhite}
                            alt={'Logo do NAPI Solar'}
                        />
                    ) : (
                        React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: 'trigger',
                                onClick: () => setCollapsed(!collapsed),
                            }
                        )
                    )}
                    <Dropdown menu={{ items }} placement="bottomRight">
                        <Button
                            type="text"
                            shape={isMobileLayout ? 'circle' : 'default'}
                            style={{
                                marginLeft: 'auto',
                            }}
                        >
                            <UserOutlined
                                style={
                                    isMobileLayout
                                        ? { color: 'white' }
                                        : undefined
                                }
                            />
                            {!isMobileLayout &&
                                getNomeUsuario() !== null &&
                                getNomeUsuario().split(' ')[0]}
                        </Button>
                    </Dropdown>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 0,
                        minHeight: 280,
                        paddingBottom: width < 500 ? 96 : 0,
                        background: 'transparent',
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

GlobalLayout.propTypes = {}

export default GlobalLayout
