import React from 'react'
import './css/app.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import { App as AntApp, ConfigProvider } from 'antd'
import Cadastro from './pages/Cadastro'
import RecuperarSenha from './pages/RecuperarSenha'
import ResetarSenha from './pages/ResetarSenha'
import Dashboard from './pages/Dashboard'
import Usinas from './pages/Usinas/Usinas'
import Componentes from './pages/Componentes/Componentes'
import MinhaConta from './pages/MinhaConta'
import GlobalLayout from './layout/GlobalLayout'
import Auth from './utils/Auth'
import ProtectedRoute from './utils/ProtectedRoute'
import ptBR from 'antd/es/locale/pt_BR'
import NovoComponente from './pages/Componentes/NovoComponente'
import Administracao from './pages/Administracao/Administracao'
import CookieDisclaimer from './components/CookieDisclaimer'
import PWAInstallPrompt from './components/PWAInstallPrompt'
import usePWAInstall from './utils/usePWAInstall'
import useWindowDimensions from './utils/useWindowDimensions'
import UsinaEspecifica from './pages/Usinas/UsinaEspecifica/UsinaEspecifica'
import NovaModificarUsina from './pages/Usinas/UsinaEspecifica/NovaModificarUsina'
import NovaModificarInspecao from './pages/Usinas/UsinaEspecifica/Inspecao/NovaModificarInspecao'

function App() {
    const { isInstalled } = usePWAInstall()
    const { isMobileLayout } = useWindowDimensions()

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#0AA107',
                        colorLink: '#0AA107',
                        colorLinkActive: '#087006',
                        colorLinkHover: '#2cc956',
                        fontSize: isInstalled && isMobileLayout ? 16 : 14,
                    },
                }}
                locale={ptBR}
            >
                <AntApp>
                    <BrowserRouter>
                        <PWAInstallPrompt />
                        <CookieDisclaimer />
                        <Auth>
                            <Routes>
                                <Route path={'/'} element={<Login />} />
                                <Route path={'/login'} element={<Login />} />
                                <Route
                                    path={'/cadastro'}
                                    element={<Cadastro />}
                                />
                                <Route
                                    path={'/recuperarsenha'}
                                    element={<RecuperarSenha />}
                                />
                                <Route
                                    path={'resetarsenha'}
                                    element={<ResetarSenha />}
                                />
                                <Route
                                    path={'/app'}
                                    element={
                                        <ProtectedRoute
                                            authorities={['USUARIO', 'ADMIN']}
                                        >
                                            <GlobalLayout />
                                        </ProtectedRoute>
                                    }
                                >
                                    <Route
                                        path={'dashboard'}
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path={'usinas'}
                                        element={<Usinas />}
                                    />
                                    <Route
                                        path={'usinas/nova'}
                                        element={<NovaModificarUsina />}
                                    />
                                    <Route
                                        path={'usinas/:id/modificar'}
                                        element={<NovaModificarUsina />}
                                    />
                                    <Route
                                        path={
                                            'usinas/:id/inspecao/:idInspecao/*'
                                        }
                                        element={<NovaModificarInspecao />}
                                    />
                                    <Route
                                        path={'usinas/:id/inspecao/nova/*'}
                                        element={<NovaModificarInspecao />}
                                    />
                                    <Route
                                        path={
                                            'usinas/:id/inspecao/:idInspecao/modificar/*'
                                        }
                                        element={<NovaModificarInspecao />}
                                    />
                                    <Route
                                        path={'usinas/:id/*'}
                                        element={<UsinaEspecifica />}
                                    />
                                    <Route
                                        path={'administracao/*'}
                                        element={
                                            <ProtectedRoute
                                                authorities={['ADMIN']}
                                            >
                                                <Administracao />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={'componentes/novo'}
                                        element={<NovoComponente />}
                                    />
                                    <Route
                                        path={'componentes/*'}
                                        element={<Componentes />}
                                    />
                                    <Route
                                        path={'minhaconta/*'}
                                        element={<MinhaConta />}
                                    />
                                </Route>
                            </Routes>
                        </Auth>
                    </BrowserRouter>
                </AntApp>
            </ConfigProvider>
        </>
    )
}

export default App
