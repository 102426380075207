import React, { useContext, useEffect, useState } from 'react'
import {
    App,
    Avatar,
    Button,
    Col,
    Drawer,
    List,
    Modal,
    Row,
    Select,
    Space,
    Tag,
    Typography,
} from 'antd'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import { useLocation, useNavigate } from 'react-router-dom'
import { EditOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { AccountContext } from '../../../utils/Auth'
import { useLocalDb } from '../../../utils/useLocalDb'
import usePWAInstall from '../../../utils/usePWAInstall'

interface EquipeDrawerProps {
    open: boolean
    setOpen: (open: boolean) => void
    idUsina: string | undefined
    controlador: { id: number; nome: string; email: string }
    refreshControlador: () => void
}

const EquipeDrawer = (props: EquipeDrawerProps) => {
    const { message } = App.useApp()
    const { authenticatedFetch, getEmailUsuario } = useContext(AccountContext)
    const [data, setData] = useState<any>(undefined)
    const [selectedMember, setSelectedMember] = useState<any>({
        id: -1,
        nome: '',
        email: '',
    })
    const [pagina, setPagina] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalElementos, setTotalElementos] = useState<number>(0)
    const [isTransferirControleModal, setTransferirControleModalOpen] =
        useState<boolean>(false)
    const [isRemoverMembroModalOpen, setRemoverMembroModalOpen] =
        useState<boolean>(false)
    const [isModificarMembroDrawerOpen, setModificarMembroDrawerOpen] =
        useState<boolean>(false)
    const [isAdicionarMembroModalOpen, setAdicionarMembroModalOpen] =
        useState<boolean>(false)
    const [novoMembro, setNovoMembro] = useState<number | undefined>(undefined)
    const [usuarios, setUsuarios] = useState<any[]>([])
    const { isOnline } = useLocalDb()
    const { isInstalled } = usePWAInstall()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (props.open) {
            if (
                !location.pathname.includes(
                    '/app/usinas/' + props.idUsina + '/equipe'
                )
            ) {
                props.setOpen(false)
            }
            if (
                !location.pathname.includes(
                    '/app/usinas/' + props.idUsina + '/equipe/'
                )
            ) {
                setModificarMembroDrawerOpen(false)
            }
            if (!location.pathname.includes('removerMembro')) {
                setRemoverMembroModalOpen(false)
            }
            if (!location.pathname.includes('transferirControle')) {
                setTransferirControleModalOpen(false)
            }
        }
    }, [props.open, location])

    useEffect(() => {
        getEquipe()
    }, [props.controlador])

    useEffect(() => {
        if (isAdicionarMembroModalOpen) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usuario/listarUsuarios',
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((resData: any[]) => {
                            let equipeIds = data.map((e: any) => e.id)
                            let usuariosFiltrados = resData.filter(
                                (e) => !equipeIds.includes(e.id)
                            )

                            setUsuarios(usuariosFiltrados)
                        })
                    }
                })
                .catch(() => {
                    if (isOnline)
                        message.error('Erro ao tentar contactar o servidor', 8)
                })
        }
    }, [isAdicionarMembroModalOpen])

    const getEquipe = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usinas/equipe?idUsina=' +
                props.idUsina,
            'GET'
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setData([props.controlador, ...data])
                        })
                    } else if (res.status === 404) {
                        message.error({
                            key: 'equipeFetchError',
                            content: 'Usina não encontrada',
                            duration: 8,
                        })
                    }
                }
            )
            .catch(() => {
                if (isOnline)
                    message.error({
                        key: 'equipeFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
            })
    }

    const transferirControle = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usinas/equipe/transferirControle?idUsina=' +
                props.idUsina +
                '&idInspetor=' +
                selectedMember.id,
            'POST'
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.status === 200) {
                        message.success({
                            key: 'equipeFetchSuccess',
                            content: 'Controle transferido com sucesso',
                            duration: 6,
                        })
                        props.refreshControlador()
                        setTransferirControleModalOpen(false)
                        setModificarMembroDrawerOpen(false)
                        navigate(-2)
                    } else {
                        message.error({
                            key: 'equipeFetchError',
                            content:
                                'Ocorreu um erro ao tentar transferir o controle da usina',
                            duration: 6,
                        })
                    }
                }
            )
            .catch(() => {
                message.error({
                    key: 'equipeFetchError',
                    content: 'Erro ao tentar contactar o servidor',
                    duration: 8,
                })
            })
    }

    const removerMembro = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usinas/equipe?idUsina=' +
                props.idUsina +
                '&idInspetor=' +
                selectedMember.id,
            'DELETE'
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.status === 200) {
                        message.success({
                            key: 'equipeFetchSuccess',
                            content: 'Membro removido com sucesso',
                            duration: 6,
                        })
                        getEquipe()
                        setRemoverMembroModalOpen(false)
                        setModificarMembroDrawerOpen(false)
                        navigate(-2)
                    } else {
                        message.error({
                            key: 'equipeFetchError',
                            content:
                                'Ocorreu um erro ao tentar remover o membro da equipe',
                            duration: 6,
                        })
                    }
                }
            )
            .catch(() => {
                message.error({
                    key: 'equipeFetchError',
                    content: 'Erro ao tentar contactar o servidor',
                    duration: 8,
                })
            })
    }

    const adicionarMembro = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usinas/equipe?idUsina=' +
                props.idUsina +
                '&idInspetor=' +
                novoMembro,
            'POST'
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.status === 200) {
                        message.success({
                            key: 'equipeFetchSuccess',
                            content: 'Membro adicionado com sucesso',
                            duration: 6,
                        })
                        getEquipe()
                        setAdicionarMembroModalOpen(false)
                        setModificarMembroDrawerOpen(false)
                        setNovoMembro(undefined)
                        navigate(-2)
                    } else {
                        message.error({
                            key: 'equipeFetchError',
                            content:
                                'Ocorreu um erro ao tentar adicionar o membro na equipe',
                            duration: 6,
                        })
                    }
                }
            )
            .catch(() => {
                message.error({
                    key: 'equipeFetchError',
                    content: 'Erro ao tentar contactar o servidor',
                    duration: 8,
                })
            })
    }

    interface DataType {
        key: string
        id: number
        nome: string
        email: string
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
            ellipsis: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            responsive: ['lg'],
        },
        {
            //title: 'Ações',
            key: 'action',
            align: 'right',
            render: (_, record) => (
                <Button
                    shape={'circle'}
                    onClick={(e) => {
                        // setUsuarioData(record)
                        // setModificarUsuarioOpen(true)
                        // navigate('/app/administracao/usuario/' + record.id)
                    }}
                    icon={<EditOutlined />}
                />
            ),
        },
    ]

    const transferirControleModal = (
        <Modal
            title={
                <>
                    <WarningOutlined /> Transferir controle
                </>
            }
            open={isTransferirControleModal}
            okText="Transferir"
            cancelText="Cancelar"
            onCancel={(e) => {
                setTransferirControleModalOpen(false)
                navigate(-1)
            }}
            onOk={(e) => {
                transferirControle()
            }}
            okButtonProps={{ danger: true }}
            zIndex={1001}
        >
            A transferência de controle permite que o recipiente tenha total
            controle sobre os dados da usina. Deseja continuar?
        </Modal>
    )

    const removerMembroModal = (
        <Modal
            title={<>Remover membro</>}
            open={isRemoverMembroModalOpen}
            okText="Remover"
            cancelText="Cancelar"
            onCancel={(e) => {
                setRemoverMembroModalOpen(false)
                navigate(-1)
            }}
            onOk={(e) => {
                removerMembro()
            }}
            zIndex={1001}
        >
            Ao remover um membro ele deixa de poder realizar inspeções na usina.
        </Modal>
    )

    const adicionarMembroModal = (
        <Modal
            title={<>Adicionar membro</>}
            open={isAdicionarMembroModalOpen}
            okButtonProps={{ disabled: !novoMembro }}
            okText="Adicionar"
            cancelText="Cancelar"
            onCancel={(e) => {
                setAdicionarMembroModalOpen(false)
                setNovoMembro(undefined)
                navigate(-1)
            }}
            onOk={(e) => {
                adicionarMembro()
            }}
            zIndex={1001}
        >
            <Row>
                Ao adicionar um membro ele poderá realizar inspeções na usina.
            </Row>
            <Row>
                <Select
                    getPopupContainer={() =>
                        document.getElementsByClassName(
                            'ant-modal-body'
                        )[0] as HTMLElement
                    }
                    style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
                    placeholder={'Busque pelo email'}
                    allowClear={true}
                    value={novoMembro}
                    showSearch
                    onChange={(e) => setNovoMembro(e)}
                    options={usuarios?.map((e) => ({
                        label: (
                            <>
                                <Typography.Text>{e.nome}</Typography.Text>
                                <Typography.Text type={'secondary'}>
                                    {' ('}
                                    {e.email}
                                    {')'}
                                </Typography.Text>
                            </>
                        ),
                        value: e.id,
                    }))}
                />
            </Row>
        </Modal>
    )

    return (
        <>
            {transferirControleModal}
            {removerMembroModal}
            {adicionarMembroModal}
            <Drawer
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                placement="right"
                closable={true}
                onClose={(e) => {
                    props.setOpen(false)
                    //setIsEditMode(false)
                    navigate(-1)
                }}
                open={props.open}
                title="Equipe"
            >
                <List
                    dataSource={data}
                    renderItem={(item: {
                        email: string
                        nome: string
                        id: number
                    }) => (
                        <List.Item
                            className={'equipe-list'}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            key={item.id}
                            actions={[
                                props.controlador.id === item.id ||
                                props.controlador.email !==
                                    getEmailUsuario() ? (
                                    <></>
                                ) : (
                                    <a
                                        onClick={(e) => {
                                            setSelectedMember(item)
                                            setModificarMembroDrawerOpen(true)
                                            navigate(
                                                '/app/usinas/' +
                                                    props.idUsina +
                                                    '/equipe/' +
                                                    item.id
                                            )
                                        }}
                                        key={`a-${item.id}`}
                                    >
                                        Editar
                                    </a>
                                ),
                            ]}
                        >
                            <List.Item.Meta
                                style={{ alignItems: 'center' }}
                                avatar={<Avatar icon={<UserOutlined />} />}
                                title={<>{item.nome}</>}
                                description={
                                    <>
                                        {item.email}
                                        <br />
                                        {props.controlador.id === item.id && (
                                            <Tag
                                                color="geekblue"
                                                style={{ marginTop: 8 }}
                                            >
                                                Controlador
                                            </Tag>
                                        )}
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
                {props.controlador.email === getEmailUsuario() && (
                    <Button
                        style={{ width: '100%', marginTop: 16 }}
                        onClick={(e) => {
                            setAdicionarMembroModalOpen(true)
                            navigate(
                                '/app/usinas/' +
                                    props.idUsina +
                                    '/equipe' +
                                    '/adicionarMembro'
                            )
                        }}
                    >
                        Adicionar membro
                    </Button>
                )}
            </Drawer>
            <Drawer
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                placement="right"
                closable={true}
                onClose={(e) => {
                    setModificarMembroDrawerOpen(false)
                    navigate(-1)
                }}
                open={isModificarMembroDrawerOpen}
                title="Modificar membro"
            >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Row align={'middle'} style={{ marginBottom: 16 }}>
                        <Col>
                            <Avatar
                                style={{ marginRight: 16 }}
                                icon={<UserOutlined />}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <Typography.Text strong>
                                    {selectedMember.nome}
                                </Typography.Text>
                            </Row>
                            <Row>{selectedMember.email}</Row>
                        </Col>
                    </Row>
                    <Button
                        danger
                        style={{ width: '100%' }}
                        icon={<WarningOutlined />}
                        onClick={(e) => {
                            setTransferirControleModalOpen(true)
                            navigate(
                                '/app/usinas/' +
                                    props.idUsina +
                                    '/equipe/' +
                                    props.controlador.id +
                                    '/transferirControle'
                            )
                        }}
                    >
                        Transferir controle
                    </Button>
                    <Button
                        style={{ width: '100%' }}
                        onClick={(e) => {
                            setRemoverMembroModalOpen(true)
                            navigate(
                                '/app/usinas/' +
                                    props.idUsina +
                                    '/equipe/' +
                                    props.controlador.id +
                                    '/removerMembro'
                            )
                        }}
                    >
                        Remover da equipe
                    </Button>
                </Space>
            </Drawer>
        </>
    )
}

export default EquipeDrawer
