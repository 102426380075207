import { useEffect, useState } from 'react'

export interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[]
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed'
        platform: string
    }>
    prompt(): Promise<void>
}

export interface Options {
    acceptedFn?: () => void
    dismissedFn?: () => void
    installedFn?: () => void
}

export interface ReturnType {
    isInstalled: boolean
    install: () => Promise<false | void>
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent
    }
}

function getAppMode() {
    let appDisplayMode = 'browser tab'
    if (window.matchMedia('(display-mode: standalone)').matches) {
        appDisplayMode = 'standalone'
    }

    return appDisplayMode
}

const usePWAInstall = (options?: Options): ReturnType => {
    const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] =
        useState<BeforeInstallPromptEvent>()
    const [isInstalled, setIsInstalled] = useState(false)

    useEffect(() => {
        if (getAppMode() === 'standalone') {
            setIsInstalled(true)
        }
    }, [])

    const install = async () => {
        if (!beforeInstallPromptEvent) return false

        beforeInstallPromptEvent.prompt()

        const { outcome } = await beforeInstallPromptEvent.userChoice

        if (outcome === 'accepted') {
            setIsInstalled(true)
        } else {
        }
    }

    useEffect(() => {
        const handler = (e: BeforeInstallPromptEvent) => {
            e.preventDefault()
            setBeforeInstallPromptEvent(e)
        }

        window.addEventListener('beforeinstallprompt', handler)

        return () => {
            window.removeEventListener('beforeinstallprompt', handler)
        }
    }, [])

    useEffect(() => {
        const handler = () => {
            setIsInstalled(true)
            // For apply setIsInstalled(true) when install and popup new pwa
            window.location.reload()
        }

        window.addEventListener('appinstalled', handler)

        return () => {
            window.removeEventListener('appinstalled', handler)
        }
    }, [])

    return { isInstalled, install }
}

export default usePWAInstall
