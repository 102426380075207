import React from 'react'
import { Col, Row } from 'antd'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'

type Props = {
    currentStep: number
    formType: string
}

const loadedQuestions = questions()

const FormStep7 = (props: Props) => {
    return (
        <>
            <Row>
                <Col md={24} lg={18} xl={14} xxl={10}>
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q60, renderFIObs: false }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q61, renderFIObs: false }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q63 }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q64 }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q62 }}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={{ ...loadedQuestions.q65 }}
                        formType={props.formType}
                    />
                </Col>
            </Row>
        </>
    )
}

export default FormStep7
