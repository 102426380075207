import React, { ReactElement } from 'react'
import { DatePicker, Divider, Form, Input, InputNumber, Radio, Select } from 'antd'

type Props = {
    question: {
        field_name: string | Array<any>
        description: JSX.Element
        allowed: Array<string>
        type: string
        addon_before?: string
        addon_after?: string
        max?: number
        min?: number
        value?: number
        integer_only?: boolean
        options?: Array<{ value: string; label: string }> | undefined
        renderFIObs?: boolean
    }
    formType: string
}
export const RenderFonteInformacaoAndObservacoes = (
    fieldName: string | Array<any>,
) => {
    const form = Form.useFormInstance()
    const fieldValue = Form.useWatch(fieldName, form)

    return (
        (fieldValue !== 'null' && fieldValue !== false) ? (
            <>
                <Form.Item
                    label={'Fonte da informação'}
                    name={fieldName + 'FI'}
                    rules={[
                        {
                            required: true,
                            message: 'Campo obrigatório',
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'PRODUTOR', label: 'Produtor' },
                            {
                                value: 'PROJETO_ELETRICO',
                                label: 'Projeto elétrico',
                            },
                            { value: 'IDR', label: 'IDR' },
                            {
                                value: 'VISITA',
                                label: 'Observado durante visita',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item label={'Observações'} name={fieldName + 'Obs'}>
                    <Input.TextArea rows={4} maxLength={255} />
                </Form.Item>
            </>
        ) : (
            <>
                <Form.Item
                    label={'Fonte da informação'}
                    name={fieldName + 'FI'}
                    rules={[
                        {
                            required: true,
                            message: 'Campo obrigatório',
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'PRODUTOR', label: 'Produtor' },
                            {
                                value: 'PROJETO_ELETRICO',
                                label: 'Projeto elétrico',
                            },
                            { value: 'IDR', label: 'IDR' },
                            {
                                value: 'VISITA',
                                label: 'Observado durante visita',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label={'Observações'}
                    name={fieldName + 'Obs'}
                    rules={[
                        {
                            required: true,
                            message: 'Campo obrigatório',
                        },
                    ]}
                >
                    <Input.TextArea rows={4} maxLength={255} />
                </Form.Item>
            </>
        )
    )
}

export const renderSelectionField = ({
                                         fieldName,
                                         fieldLabel,
                                         renderFIObs = true,
                                         options,
                                     }: {
    fieldName: string | Array<any>,
    fieldLabel: string | ReactElement,
    renderFIObs?: boolean,
    options?: Array<{ value: string; label: string }>,
}) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select options={options} />
            </Form.Item>
            {renderFIObs && RenderFonteInformacaoAndObservacoes(fieldName)}
        </>
    )
}

export const renderRadioField = (
    fieldName: string | Array<any>,
    fieldLabel: string | ReactElement,
) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                    <Radio value={'null'}>Não avaliado</Radio>
                </Radio.Group>
            </Form.Item>
            {RenderFonteInformacaoAndObservacoes(fieldName)}
        </>
    )
}

export const renderNumberField = ({
                                      fieldName,
                                      fieldLabel,
                                      addonAfter,
                                      addonBefore,
                                      max,
                                      min,
                                      step,
                                      integerOnly,
                                      renderFIObs = true,
                                  }: {
    fieldName: string | Array<any>
    fieldLabel: string | ReactElement
    addonAfter?: string
    addonBefore?: string
    max?: number
    min?: number
    step?: number
    integerOnly?: boolean
    renderFIObs?: boolean
}) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                        message: 'Número inválido',
                        pattern: integerOnly ? /^[0-9]*$/ : /.*/,
                        min: min,
                        max: max,
                    },
                ]}
            >
                <InputNumber
                    decimalSeparator={','}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    min={min}
                    max={max}
                    step={step}
                />
            </Form.Item>
            {renderFIObs && RenderFonteInformacaoAndObservacoes(fieldName)}
        </>
    )
}

export const renderReadonlyNumberField = ({
                                              fieldName,
                                              fieldLabel,
                                              addonAfter,
                                              addonBefore,
                                              value,
                                          }: {
    fieldName: string | Array<any>;
    fieldLabel: string | ReactElement;
    addonAfter?: string;
    addonBefore?: string;
    value?: number;
}) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <InputNumber
                    value={value}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    readOnly
                    style={{ width: '20%' }}
                />
            </Form.Item>
        </>
    )
}


export const renderTextField = (
    fieldName: string | Array<any>,
    fieldLabel: string | ReactElement,
    renderFIObs = true,
) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            {renderFIObs && RenderFonteInformacaoAndObservacoes(fieldName)}
        </>
    )
}

export const renderTextArea = (
    fieldName: string | Array<any>,
    fieldLabel: string | ReactElement,
) => {
    return (
        <>
            <Form.Item label={fieldLabel} name={fieldName}>
                <Input.TextArea rows={4} maxLength={255} />
            </Form.Item>
        </>
    )
}

export const renderDateField = ({
                                    fieldName,
                                    fieldLabel,
                                }: {
    fieldName: string | Array<any>,
    fieldLabel: string | ReactElement,
}) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                        message: 'Selecione uma data válida',
                    },
                ]}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione a data"
                    onChange={(date, dateString) => console.log(date, dateString)}
                />
            </Form.Item>
        </>
    )
}

export const renderDoubleField = ({
                                      fieldName,
                                      fieldLabel,
                                      addonAfter,
                                      addonBefore,
                                      max,
                                      min,
                                  }: {
    fieldName: string | Array<any>
    fieldLabel: string | ReactElement
    addonAfter?: string
    addonBefore?: string
    max?: number
    min?: number
    step?: number
}) => {
    return (
        <>
            <Form.Item
                label={fieldLabel}
                name={fieldName}
                rules={[
                    {
                        required: true,
                        message: 'Número inválido',
                        type: 'number',
                        min: min,
                        max: max,
                    },
                ]}
            >
                <InputNumber
                    decimalSeparator={'.'}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    min={min}
                    max={max}
                    precision={7}
                    style={{ width: '17%' }}
                />
            </Form.Item>
        </>
    )
}


const QuestionWrapper = (props: Props) => {
    return (
        <>
            {props.question.allowed.includes(props.formType) && (
                <>
                    {props.question.type === 'boolean' &&
                        renderRadioField(
                            props.question.field_name,
                            props.question.description,
                        )}
                    {props.question.type === 'integer' &&
                        renderNumberField({
                            fieldName: props.question.field_name,
                            fieldLabel: props.question.description,
                            integerOnly: props.question.integer_only,
                            addonAfter: props.question.addon_after,
                            addonBefore: props.question.addon_before,
                            max: props.question.max,
                            min: props.question.min,
                            renderFIObs: props.question.renderFIObs,
                        })}
                    {props.question.type === 'readonlyInteger' &&
                        renderReadonlyNumberField({
                            fieldName: props.question.field_name,
                            fieldLabel: props.question.description,
                            addonAfter: props.question.addon_after,
                            addonBefore: props.question.addon_before,
                            value: props.question.value ?? 0,
                        })}
                    {props.question.type === 'selection' &&
                        renderSelectionField({
                            fieldName: props.question.field_name,
                            fieldLabel: props.question.description,
                            renderFIObs: props.question.renderFIObs,
                            options: props.question.options,
                        })}
                    {props.question.type === 'textfield' &&
                        renderTextField(
                            props.question.field_name,
                            props.question.description,
                            props.question.renderFIObs,
                        )}
                    {props.question.type === 'textarea' &&
                        renderTextArea(
                            props.question.field_name,
                            props.question.description,
                        )}
                    {props.question.type === 'date' &&
                        renderDateField({
                            fieldName: props.question.field_name,
                            fieldLabel: props.question.description,
                        })}
                    {props.question.type === 'double' &&
                        renderDoubleField({
                            fieldName: props.question.field_name,
                            fieldLabel: props.question.description,
                            addonAfter: props.question.addon_after,
                            addonBefore: props.question.addon_before,
                            max: props.question.max,
                            min: props.question.min,
                        })}
                    <Divider />
                </>
            )}
        </>
    )
}

export default QuestionWrapper
