import React from 'react'
import { Button, Row } from 'antd'
import '../css/float-nav-menu.css'
import { useNavigate } from 'react-router-dom'
import { ItemType, MenuItemType } from 'antd/es/menu/interface'

type Props = {
    items: MenuItemType[] | ItemType[] | undefined
    selectedKey: string
}
const FloatNavMenu = (props: Props) => {
    const navigate = useNavigate()

    return (
        <div className={'float-nav-menu-container'}>
            <Row className={'float-nav-menu'}>
                {props.items &&
                    props.items.map((item: MenuItemType | ItemType) => {
                        let href = '#'

                        if (
                            item &&
                            'label' in item &&
                            item.label &&
                            React.isValidElement(item.label) &&
                            'to' in item.label.props
                        ) {
                            href = item.label.props.to
                        }

                        return (
                            <Button
                                // @ts-ignore
                                disabled={item !== null && item.disabled}
                                key={item !== null ? item.key : undefined}
                                onClick={() => navigate(href)}
                                className={'float-nav-menu-btn'}
                                type={
                                    item !== null &&
                                    item.key === props.selectedKey
                                        ? 'primary'
                                        : 'text'
                                }
                                size="large"
                                icon={
                                    item !== null &&
                                    'icon' in item &&
                                    item.icon !== undefined
                                        ? item.icon
                                        : undefined
                                }
                            />
                        )
                    })}
            </Row>
        </div>
    )
}

export default FloatNavMenu
