import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Col, Form, Input, Modal, Row } from 'antd'
import { AccountContext } from '../utils/Auth'
import { useLocalDb } from '../utils/useLocalDb'
import { useLocation, useNavigate } from 'react-router-dom'

const MinhaConta = () => {
    const { message } = App.useApp()
    const { authenticatedFetch } = useContext(AccountContext)
    const [isModificarSenhaOpen, setIsModificarSenhaOpen] = useState(false)
    const [contaForm] = Form.useForm()
    const [senhaForm] = Form.useForm()
    const novaSenha = Form.useWatch('novaSenha', senhaForm)
    const confirmarNovaSenha = Form.useWatch('confirmarNovaSenha', senhaForm)
    const { isOnline } = useLocalDb()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (
            isModificarSenhaOpen &&
            location.pathname !== '/app/minhaconta/alterarsenha'
        ) {
            setIsModificarSenhaOpen(false)
        }
    }, [isModificarSenhaOpen, location])

    useEffect(() => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + '/usuario/dadosCadastrais',
            'GET'
        ).then(
            (res: {
                ok: any
                json: () => Promise<{ nome: string; email: string }>
            }) => {
                if (res.ok) {
                    res.json().then((data: { nome: string; email: string }) => {
                        contaForm.setFieldsValue({
                            nome: data.nome,
                            email: data.email,
                        })
                    })
                }
            }
        )
    }, [])

    useEffect(() => {
        senhaForm.validateFields()
    }, [novaSenha, confirmarNovaSenha])

    const onFinishModificarSenha = (values: {
        senhaAtual?: string
        novaSenha?: string
        confirmarNovaSenha?: string
    }) => {
        delete values.confirmarNovaSenha

        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + '/usuario/alterarSenha',
            'PATCH',
            JSON.stringify(values)
        )
            .then(
                (res: {
                    ok: any
                    json: () => Promise<{ nome: string; email: string }>
                }) => {
                    if (res.ok) {
                        message.success('Senha alterada com sucesso!', 8)
                        senhaForm.setFieldValue('senhaAtual', '')
                        senhaForm.setFieldValue('novaSenha', '')
                        senhaForm.setFieldValue('confirmarNovaSenha', '')
                        setIsModificarSenhaOpen(false)
                        navigate(-1)
                    } else {
                        message.error('Erro ao tentar alterar a senha', 8)
                    }
                }
            )
            .catch((err: any) => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    const onFinishModificarConta = (values: {
        nome: string
        email: string
    }) => {
        let body = values
        body['nome'] = body['nome'].trim()

        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + '/usuario/dadosCadastrais',
            'PATCH',
            JSON.stringify(body)
        )
            .then((res: { ok: any }) => {
                if (res.ok) {
                    message.success('Dados alterados com sucesso!', 8)
                } else {
                    message.error('Erro ao tentar alterar os dados', 8)
                }
            })
            .catch((err: any) => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    return (
        <>
            <Card title={'Minha conta'}>
                <Form
                    name={'alterarDadosCadastrais'}
                    form={contaForm}
                    layout="vertical"
                    onFinish={onFinishModificarConta}
                >
                    <Row>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item
                                name="nome"
                                label="Nome completo"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor entre seu nome e sobrenome',
                                        pattern:
                                            /^[a-zA-ZéêãáâóôõúçÉÊÃÁÂÓÔÕÚÇ]+(?: [a-zA-ZéêãáâóôõúçÉÊÃÁÂÓÔÕÚÇ]+)/,
                                    },
                                ]}
                                normalize={(value) => {
                                    const arr = value.split(' ')

                                    for (let i = 0; i < arr.length; i++) {
                                        arr[i] =
                                            arr[i].charAt(0).toUpperCase() +
                                            arr[i].slice(1)
                                    }

                                    return arr.join(' ')
                                }}
                            >
                                <Input placeholder="Nome completo" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item name="email" label="E-mail">
                                <Input placeholder="E-mail" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item>
                                <Button
                                    disabled={!isOnline}
                                    type="default"
                                    onClick={(e) => {
                                        navigate('/app/minhaconta/alterarsenha')
                                        setIsModificarSenhaOpen(true)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    Modificar senha
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item>
                                <Button
                                    disabled={!isOnline}
                                    type="primary"
                                    htmlType={'submit'}
                                    style={{ width: '100%' }}
                                >
                                    Salvar alterações
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Modal
                title="Modificar senha"
                open={isModificarSenhaOpen}
                onOk={(e) => senhaForm.submit()}
                onCancel={(e) => {
                    setIsModificarSenhaOpen(false)
                    navigate(-1)
                }}
                okText={'Alterar senha'}
            >
                <Form
                    form={senhaForm}
                    name="modificarSenha"
                    onFinish={(values) => onFinishModificarSenha(values)}
                >
                    <Form.Item name="senhaAtual">
                        <Input.Password
                            placeholder="Senha atual"
                            visibilityToggle={true}
                            maxLength={16}
                        />
                    </Form.Item>
                    <Form.Item
                        name="novaSenha"
                        hasFeedback={true}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Escolha uma senha com 8-16 caracteres dentre os quais ao menos 1 letra e 1 número',
                                pattern:
                                    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('confirmarNovaSenha') ===
                                            value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'As duas senhas inseridas não são iguais'
                                        )
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder="Nova senha"
                            visibilityToggle={true}
                            maxLength={16}
                        />
                    </Form.Item>
                    <Form.Item
                        hasFeedback={true}
                        name="confirmarNovaSenha"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor confirme sua nova senha',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('novaSenha') === value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'As duas senhas inseridas não são iguais'
                                        )
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder="Confirme sua nova senha"
                            visibilityToggle={true}
                            maxLength={16}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

MinhaConta.propTypes = {}

export default MinhaConta
