import React from 'react'
import { Button, Card, Form, Input, message, Row, Space } from 'antd'
import NapiLogo from '../images/napi_logo_horizontal.svg'
import '../css/auth.css'
import { useNavigate } from 'react-router-dom'

const RecuperarSenha = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const onFinish = () => {
        fetch(
            process.env.REACT_APP_SERVER_IP + '/auth/solicitarPinTrocaSenha',
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify(form.getFieldsValue()),
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    message.success(
                        <>
                            Solicitação feita com sucesso!
                            <br />
                            Verifique o seu e-mail para mais instruções
                        </>
                    )
                    navigate('/login')
                } else if (res.status === 404) {
                    message.error(
                        <>Não existe conta associada com o email informado</>
                    )
                }
            })
            .catch((err) =>
                message.error(
                    'Erro ao contactar o servidor, tente novamente mais tarde'
                )
            )
    }

    return (
        <div className={'auth-bg'}>
            <div className={'auth-container'}>
                <Space
                    direction="vertical"
                    size={'large'}
                    style={{ width: '100%' }}
                >
                    <Row align={'middle'} justify={'center'}>
                        <img
                            src={NapiLogo}
                            alt={'Logo do NAPI Solar'}
                            width={300}
                            style={{ display: 'block' }}
                        />
                    </Row>

                    <Row align={'middle'} justify={'center'}>
                        <Card className={'auth-card'}>
                            <Form
                                name="recuperar_senha"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                form={form}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message:
                                                'Por favor entre seu email',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Email"
                                        size={'large'}
                                        maxLength-={50}
                                    />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    size={'large'}
                                >
                                    Solicitar nova senha
                                </Button>
                            </Form>
                        </Card>
                    </Row>
                </Space>
            </div>
        </div>
    )
}

RecuperarSenha.propTypes = {}

export default RecuperarSenha
