import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Divider, Input, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { AccountContext } from '../utils/Auth'
import usePWAInstall from '../utils/usePWAInstall'
import { useLocalDb } from '../utils/useLocalDb'

interface FabricanteFieldProps {
    tipoComponente?: 'inversor' | 'painel' | 'transformador' | undefined
    isInsideDrawer?: boolean
}

const FabricanteWithAddField = ({
    tipoComponente,
    isInsideDrawer,
    ...props
}: FabricanteFieldProps) => {
    const { message } = App.useApp()
    const { authenticatedFetch } = useContext(AccountContext)
    const [fabricantes, setFabricantes] = useState<String[]>([])
    const [novoFabricante, setNovoFabricante] = useState('')
    const { isInstalled } = usePWAInstall()
    const { getFabricantesFromLocalDb } = useLocalDb()

    const addFabricante = () => {
        setFabricantes([...fabricantes, novoFabricante])
        setNovoFabricante('')
    }

    useEffect(() => {
        if (tipoComponente !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    tipoComponente +
                    '/fabricantes',
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setFabricantes(data)
                        })
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        getFabricantesFromLocalDb(tipoComponente).then((data) =>
                            setFabricantes(data)
                        )
                    } else {
                        message.error({
                            key: 'componentesFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }, [tipoComponente, isInstalled])

    return (
        <Select
            {...props}
            getPopupContainer={
                isInsideDrawer
                    ? () =>
                          document.getElementsByClassName(
                              'ant-drawer-body'
                          )[0] as HTMLElement
                    : undefined
            }
            disabled={tipoComponente === undefined}
            placeholder="Fabricante"
            showSearch={true}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Input
                            placeholder="Nome"
                            value={novoFabricante}
                            onChange={(e) =>
                                setNovoFabricante(e.target.value.toUpperCase())
                            }
                        />
                        <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addFabricante}
                        />
                    </Space>
                </>
            )}
            options={fabricantes.map((fabricante) => ({
                label: fabricante,
                value: fabricante,
            }))}
        />
    )
}

export default FabricanteWithAddField
