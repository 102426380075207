import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Col, Row, Select, Space, Table } from 'antd'
import Search from 'antd/es/input/Search'
import { AccountContext } from '../../utils/Auth'
import { ColumnsType } from 'antd/es/table'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import usePWAInstall from '../../utils/usePWAInstall'
import { useLocalDb } from '../../utils/useLocalDb'
import UltimaSincronizacao from '../../components/UltimaSincronizacao'

const Usinas = () => {
    const { message } = App.useApp()
    const { isInstalled } = usePWAInstall()
    const [data, setData] = useState<any[]>([])
    const { authenticatedFetch } = useContext(AccountContext)
    const [pagina, setPagina] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(8)
    const [totalElementos, setTotalElementos] = useState<number>(0)
    const [regional, setRegional] = useState<string | undefined>(undefined)
    const [cidade, setCidade] = useState<string | undefined>(undefined)
    const [cidades, setCidades] = useState<string[]>([])
    const [nomeProp, setNomeProp] = useState<string>('')
    const navigate = useNavigate()
    const [usuarioId, setUsuarioId] = useState<number | null>(null)
    const { getUsinasWithPaginationFromLocalDb, isOnline, syncUsinas } =
        useLocalDb()

    useEffect(() => {
        setCidade(undefined)
        if (regional !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                '/usinas/listarCidades?regional=' +
                regional,
                'GET',
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setCidades(data)
                        })
                    }
                })
                .catch(() => {
                    message.error({
                        key: 'usinasFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                })
        }
    }, [regional])

    const fetchUsuarioIdAndSetForm = async (): Promise<number | null> => {
        try {
            const response = await authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usuario/dadosCadastrais',
                'GET',
            )

            if (response.ok) {
                const data = await response.json()
                return data.id
            } else {
                console.error('Failed to fetch user data.')
                return null
            }
        } catch (error) {
            console.error('Error fetching user data:', error)
            return null
        }
    }

    useEffect(() => {
        const fetchAndSetUsuarioId = async () => {
            const id = await fetchUsuarioIdAndSetForm()
            setUsuarioId(id)
        }

        fetchAndSetUsuarioId()

        if (usuarioId != null) {
            let requestUrl =
                process.env.REACT_APP_SERVER_IP +
                `/usinas/usuario?userId=${usuarioId}&pagina=${pagina}`

            if (regional && regional.length > 0) {
                requestUrl += `&regionalIdr=${regional}`
            }
            if (cidade && cidade.length > 0) {
                requestUrl += `&cidade=${cidade}`
            }
            if (nomeProp && nomeProp.length > 0) {
                requestUrl += `&prop=${nomeProp}`
            }

            authenticatedFetch(requestUrl, 'GET')
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any) => {
                            setData(data.content)
                            setTotalElementos(data.totalElements)
                            setPageSize(data.size)
                        })
                    }
                })
                .catch((e: any) => {
                    if (isInstalled && !isOnline) {
                        getUsinasWithPaginationFromLocalDb(
                            regional,
                            cidade,
                            nomeProp,
                            8,
                            pagina,
                        ).then((data) => {
                            setData(data.content)
                            setTotalElementos(data.totalElements)
                            setPageSize(8)
                        })
                    } else if (isOnline) {
                        message.error({
                            key: 'usinasFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }, [isInstalled, isOnline, pagina, regional, cidade, nomeProp, usuarioId])

    interface DataType {
        key: string
        id: number
        nome: string
        regionalIdr: string
        cidade: string
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Proprietário',
            dataIndex: 'proprietario',
            key: 'proprietario',
            ellipsis: true,
        },
        {
            title: 'Regional',
            dataIndex: 'regionalIdr',
            key: 'regional',
            responsive: ['lg'],
            render: (_, record) => (
                <div>
                    {
                        regionaisList.filter(
                            (e) => e.value === record.regionalIdr,
                        )[0].label
                    }
                </div>
            ),
        },
        {
            title: 'Cidade',
            dataIndex: 'cidade',
            key: 'cidade',
            ellipsis: true,
        },
        {
            title: 'Ações',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        shape={'circle'}
                        onClick={(e) => {
                            navigate('/app/usinas/' + record.id)
                        }}
                        icon={<SearchOutlined />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Card
            title={'Usinas'}
            extra={
                <Button
                    type={'primary'}
                    icon={<PlusOutlined />}
                    onClick={(e) => navigate('/app/usinas/nova')}
                >
                    Adicionar
                </Button>
            }
            actions={
                isInstalled
                    ? [
                        <UltimaSincronizacao
                            tabela={'usina'}
                            onSync={syncUsinas}
                        />,
                    ]
                    : undefined
            }
        >
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                        <Select
                            value={regional}
                            onChange={(value) => {
                                setRegional(value)
                            }}
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Regional"
                            options={regionaisList}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                        <Select
                            value={cidade}
                            onChange={(value) => {
                                setCidade(value)
                            }}
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Cidade"
                            disabled={regional === undefined}
                            options={cidades.map((cidade) => {
                                return {
                                    value: cidade,
                                    label: cidade,
                                }
                            })}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 16 }}>
                        <Search
                            placeholder="Pesquise pelo nome do proprietário"
                            onSearch={(e) => setNomeProp(e)}
                            style={{ width: '100%' }}
                            allowClear
                        />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    pagination={{
                        total: totalElementos,
                        pageSize: pageSize,
                        current: pagina + 1,
                        onChange: (page, pageSize) => setPagina(page - 1),
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </Card>
    )
}

export default Usinas

export const regionaisList = [
    {
        value: 'APUCARANA',
        label: 'Apucarana',
    },
    {
        value: 'CAMPO_MOURAO',
        label: 'Campo Mourão',
    },
    {
        value: 'CASCAVEL',
        label: 'Cascavel',
    },
    {
        value: 'CIANORTE',
        label: 'Cianorte',
    },
    {
        value: 'CORNELIO_PROCOPIO',
        label: 'Cornélio Procópio',
    },
    {
        value: 'CURITIBA',
        label: 'Curitiba',
    },
    {
        value: 'DOIS_VIZINHOS',
        label: 'Dois Vizinhos',
    },
    {
        value: 'FRANCISCO_BELTRAO',
        label: 'Francisco Beltrão',
    },
    {
        value: 'GUARAPUAVA',
        label: 'Guarapuava',
    },
    {
        value: 'IRATI',
        label: 'Irati',
    },
    {
        value: 'IVAIPORA',
        label: 'Ivaiporã',
    },
    {
        value: 'LARANJEIRAS_DO_SUL',
        label: 'Laranjeiras do Sul',
    },
    {
        value: 'LONDRINA',
        label: 'Londrina',
    },
    {
        value: 'MARINGA',
        label: 'Maringá',
    },
    {
        value: 'PARANAGUA',
        label: 'Paranaguá',
    },
    {
        value: 'PARANAVAI',
        label: 'Paranavaí',
    },
    {
        value: 'PATO_BRANCO',
        label: 'Pato Branco',
    },
    {
        value: 'PONTA_GROSSA',
        label: 'Ponta Grossa',
    },
    {
        value: 'STO_ANTONIO_DA_PLATINA',
        label: 'Sto. Antônio da Platina',
    },
    {
        value: 'TOLEDO',
        label: 'Toledo',
    },
    {
        value: 'UMUARAMA',
        label: 'Umuarama',
    },
    {
        value: 'UNIAO_DA_VITORIA',
        label: 'União da Vitória',
    },
]