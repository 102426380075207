import React, { useState } from 'react'
import { Card } from 'antd'
import Usuarios from './Usuarios'

const Administracao = () => {
    const [activeTabKey, setActiveTabKey] = useState('usuarios')

    const tabList = [
        {
            key: 'usuarios',
            tab: 'Usuários',
        },
    ]

    return (
        <>
            <Card
                title={'Administração'}
                tabList={tabList}
                activeTabKey={activeTabKey}
                onTabChange={(key) => setActiveTabKey(key)}
            >
                {activeTabKey === 'usuarios' && <Usuarios />}
            </Card>
        </>
    )
}

Administracao.propTypes = {}

export default Administracao
