import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { AccountContext } from './Auth'
import useInterval from './useInterval'

interface ProtectedRouteProps extends React.ComponentPropsWithoutRef<'input'> {
    authorities: Array<string>
}

const ProtectedRoute = ({
    children,
    authorities,
    ...rest
}: ProtectedRouteProps) => {
    const { getAccessToken, hasRefreshToken, getTipoUsuario } =
        useContext(AccountContext)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        getAccessToken() !== undefined || hasRefreshToken() !== undefined
    )
    const navigate = useNavigate()

    const checkIfUserIsLoggedInAndHasSufficientPermission = () => {
        if (getAccessToken() || hasRefreshToken()) {
            setIsLoggedIn(true)
            const role = getTipoUsuario()
            if (!authorities.includes(role)) {
                message.warning(
                    'Você não tem permissão alta o suficiente para acessar essa página',
                    5
                )
                navigate('/app/dashboard')
            }
        } else {
            setIsLoggedIn(false)
            navigate('/login')
        }
    }

    useEffect(() => {
        checkIfUserIsLoggedInAndHasSufficientPermission()
    }, [])

    useInterval(() => {
        checkIfUserIsLoggedInAndHasSufficientPermission()
    }, 60 * 1000 * 15) // Roda a cada 15 minutos

    const render = () => {
        if (isLoggedIn === undefined) {
            return <></>
        } else {
            if (isLoggedIn) {
                return children
            } else {
                return <Navigate to="/login" />
            }
        }
    }

    return <>{render()}</>
}

export default ProtectedRoute
