import React from 'react'
import { Descriptions, Space, Typography } from 'antd'
import useWindowDimensions from '../../../utils/useWindowDimensions'

type Props = {
    data: any
}

const VisualizacaoGeralUsina = (props: Props) => {
    const { isMobileLayout } = useWindowDimensions()

    const getFormattedDate = (date: Date) => {
        return new Date(date).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            //hour: 'numeric',
            //minute: '2-digit',
        })
    }

    const getSatisfacaoProprietario = (satisfacao: string) => {
        switch (satisfacao) {
            case 'BOM':
                return 'Bom'
            case 'MODERADO':
                return 'Moderado'
            case 'RUIM':
                return 'Ruim'
        }
    }

    const getTipoUsina = (tipoUsina: string) => {
        switch (tipoUsina) {
            case 'COBERTURA':
                return 'Cobertura'
            case 'SOLO':
                return 'Solo'
        }
    }

    return (
        <Space direction={'vertical'} style={{ width: '100%' }}>
            <Descriptions
                title="Dados gerais"
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                size={'middle'}
                layout={isMobileLayout ? 'vertical' : 'horizontal'}
            >
                <Descriptions.Item label="Proprietário">
                    {props.data.proprietario}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo de produção">
                    {props.data.tipoProducao}
                </Descriptions.Item>
                <Descriptions.Item label="Telefones" span={2}>
                    <Typography.Text>{props.data.telefone1}</Typography.Text>
                    {props.data.telefone2 && (
                        <>
                            <br />
                            <Typography.Text>
                                {props.data.telefone2}
                            </Typography.Text>
                        </>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Localização" span={2}>
                    Cidade: {props.data.cidade}
                    <br />
                    Endereço: {props.data.endereco}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions
                title={<div style={{ marginTop: 20 }}>Dados da instalação</div>}
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                size={'middle'}
                layout={isMobileLayout ? 'vertical' : 'horizontal'}
            >
                <Descriptions.Item label="Data de instalação">
                    {getFormattedDate(props.data.dataInstalacao)}
                </Descriptions.Item>
                <Descriptions.Item label="Empresa instaladora">
                    {props.data.empresaInstaladora}
                </Descriptions.Item>
                <Descriptions.Item label="Data de início da operação">
                    {getFormattedDate(props.data.dataInicioOperacao)}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo de usina">
                    {getTipoUsina(props.data.tipoUsina)}
                </Descriptions.Item>
            </Descriptions>
        </Space>
    )
}

export default VisualizacaoGeralUsina