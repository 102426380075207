import React from 'react'
import { App, Button, Card, Form, Input, Row, Space } from 'antd'
import NapiLogo from '../images/napi_logo_horizontal.svg'
import { useNavigate } from 'react-router-dom'
import '../css/auth.css'

const Cadastro = () => {
    const { message } = App.useApp()
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const onFinish = () => {
        let body = form.getFieldsValue(true)
        body['nome'] = body['nome'].trim()

        fetch(process.env.REACT_APP_SERVER_IP + '/auth/novoUsuario', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.status === 201) {
                    message.success('Cadastro realizado com sucesso!')
                    navigate('/login')
                } else {
                    message.error(
                        'Erro ao tentar realizar o cadastro, tente novamente mais tarde'
                    )
                }
            })
            .catch((err) =>
                message.error(
                    'Erro ao contactar o servidor, tente novamente mais tarde'
                )
            )
    }

    const isEmailEmUso = (email: String): Promise<unknown> => {
        return new Promise(function (resolve, reject) {
            fetch(
                process.env.REACT_APP_SERVER_IP +
                    '/auth/verificarExistenciaDeConta?email=' +
                    email,
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                }
            )
                .then((res) =>
                    res
                        .json()
                        .then((data) => ({ status: res.status, body: data }))
                )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.body.contaExistente != null) {
                            resolve(res.body.contaExistente)
                        } else {
                            resolve(false)
                        }
                    }
                })
        })
    }

    return (
        <div className={'auth-bg'}>
            <div className={'auth-container'}>
                <Space
                    direction="vertical"
                    size={'large'}
                    style={{ width: '100%' }}
                >
                    <Row align={'middle'} justify={'center'}>
                        <img
                            src={NapiLogo}
                            alt={'Logo do NAPI Solar'}
                            width={300}
                            style={{ display: 'block' }}
                        />
                    </Row>

                    <Row align={'middle'} justify={'center'}>
                        <Card className={'auth-card'}>
                            <Form
                                form={form}
                                name="cadastro"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hasFeedback={true}
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor entre seu nome e sobrenome',
                                            pattern:
                                                /^[a-zA-ZéêãáâóôõúçÉÊÃÁÂÓÔÕÚÇ]+(?: [a-zA-ZéêãáâóôõúçÉÊÃÁÂÓÔÕÚÇ]+)/,
                                        },
                                    ]}
                                    normalize={(value) => {
                                        const arr = value.split(' ')

                                        for (let i = 0; i < arr.length; i++) {
                                            arr[i] =
                                                arr[i].charAt(0).toUpperCase() +
                                                arr[i].slice(1)
                                        }

                                        return arr.join(' ')
                                    }}
                                >
                                    <Input
                                        placeholder="Nome completo"
                                        size={'large'}
                                        maxLength={50}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message:
                                                'Por favor entre um email válido',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                return isEmailEmUso(value).then(
                                                    (contaExistente) => {
                                                        if (contaExistente) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Já existe uma conta associada à esse email'
                                                                )
                                                            )
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder="Email"
                                        size={'large'}
                                        maxLength={50}
                                    />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback={true}
                                    name="senha"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Escolha uma senha com 8-16 caracteres dentre os quais ao menos 1 letra e 1 número',
                                            pattern:
                                                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Senha"
                                        visibilityToggle={true}
                                        maxLength={16}
                                        size={'large'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback={true}
                                    name="confirmarSenha"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor confirme sua senha',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue('senha') ===
                                                        value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'As duas senhas inseridas não são iguais'
                                                    )
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Confirme sua senha"
                                        visibilityToggle={true}
                                        maxLength={16}
                                        size={'large'}
                                    />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    size={'large'}
                                >
                                    Cadastrar
                                </Button>
                            </Form>
                        </Card>
                    </Row>
                </Space>
            </div>
        </div>
    )
}

Cadastro.propTypes = {}

export default Cadastro
