import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Input, message, Row, Space } from 'antd'
import NapiLogo from '../images/napi_logo_horizontal.svg'
import '../css/auth.css'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ResetarSenha = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const [form] = Form.useForm()
    const novaSenha = Form.useWatch('novaSenha', form)
    const confirmarNovaSenha = Form.useWatch('confirmarNovaSenha', form)
    const [possuiEmailPin, setPossuiEmailPin] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const email = searchParams.get('email')
        const pin = searchParams.get('pin')
        if (email && pin) {
            form.setFieldValue('email', searchParams.get('email'))
            form.setFieldValue('pinTrocaSenha', searchParams.get('pin'))
            setPossuiEmailPin(true)
        }
    }, [])

    useEffect(() => {
        //form.validateFields()
    }, [novaSenha, confirmarNovaSenha])

    const onFinish = () => {
        fetch(process.env.REACT_APP_SERVER_IP + '/auth/resetarSenha', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(form.getFieldsValue(true)),
        })
            .then((res) => {
                if (res.status === 200) {
                    message.success('Senha alterada com sucesso!')
                    navigate('/login')
                } else if (res.status === 401) {
                    message.error(
                        <>
                            Erro ao tentar alterar a senha. Verifique se o email
                            e PIN estão corretos
                            <br />e se a solicitação ainda está dentro do prazo
                            de 24 horas
                        </>,
                        8
                    )
                }
            })
            .catch((err) =>
                message.error(
                    'Erro ao contactar o servidor, tente novamente mais tarde'
                )
            )
    }

    return (
        <div className={'auth-bg'}>
            <div className={'auth-container'}>
                <Space
                    direction="vertical"
                    size={'large'}
                    style={{ width: '100%' }}
                >
                    <Row align={'middle'} justify={'center'}>
                        <img
                            src={NapiLogo}
                            alt={'Logo do NAPI Solar'}
                            width={300}
                            style={{ display: 'block' }}
                        />
                    </Row>

                    <Row align={'middle'} justify={'center'}>
                        <Card className={'auth-card'}>
                            <Form
                                form={form}
                                name="resetar_senha"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="email"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message:
                                                'Por favor entre um email válido',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Email"
                                        size={'large'}
                                        maxLength={50}
                                        disabled={possuiEmailPin}
                                    />
                                </Form.Item>
                                {!possuiEmailPin && (
                                    <Form.Item
                                        name="pinTrocaSenha"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /^[0-9]{6}$/,
                                                message:
                                                    'Por favor entre com o PIN',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="PIN"
                                            size={'large'}
                                            maxLength={6}
                                            disabled={possuiEmailPin}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    name="novaSenha"
                                    hasFeedback={true}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Escolha uma senha com 8-16 caracteres dentre os quais ao menos 1 letra e 1 número',
                                            pattern:
                                                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Nova senha"
                                        visibilityToggle={true}
                                        maxLength={16}
                                        size={'large'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback={true}
                                    name="confirmarNovaSenha"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor confirme sua senha',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue('senha') ===
                                                        value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'As duas senhas inseridas não são iguais'
                                                    )
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Confirme sua nova senha"
                                        visibilityToggle={true}
                                        maxLength={16}
                                        size={'large'}
                                    />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    size={'large'}
                                >
                                    Alterar senha
                                </Button>
                            </Form>
                        </Card>
                    </Row>
                </Space>
            </div>
        </div>
    )
}

ResetarSenha.propTypes = {}

export default ResetarSenha
